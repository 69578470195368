export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}${month}${day}`;
};

export const calculateNights = (checkIn?: Date, checkOut?: Date): number =>
  checkIn && checkOut
    ? Math.max(
        Math.ceil(
          (checkOut.getTime() - checkIn.getTime()) / (1000 * 60 * 60 * 24)
        ),
        1
      )
    : 0;

export const getRoomFilterValue = (value: string | null) => {
  if (value === "Any" || !value) return undefined;
  return value === "5+" ? "5" : value;
};

export const applyFilterValue = (
  value: string | undefined,
  allowedValues: string[],
  setState: React.Dispatch<React.SetStateAction<string>>
) => {
  if (value && allowedValues.includes(value)) {
    setState(value);
  }
};

export const getQueryParams = (
  search: string
): Record<string, string | undefined> => {
  const params = new URLSearchParams(search);
  return Array.from(params.entries()).reduce((acc, [key, value]) => {
    acc[key] = value || undefined;
    return acc;
  }, {} as Record<string, string | undefined>);
};
