import React from "react";
import { Booking } from "../../types/booking";
import styles from "./bookingDetails.module.scss";
import chatIcon from "../../assets/images/chat_icon.png";
import { useTranslation } from "react-i18next";

interface BookingDetailsProps {
  booking: Booking;
}

const BookingDetails: React.FC<BookingDetailsProps> = ({ booking }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.bookingDetails}>
      <h3 className={styles["title-booking-details"]}>
        {booking.autogeneratedDescription}
      </h3>
      <p className={styles["parameters-block"]}>
        {`${t("bookingDetails.guests", { count: booking.numGuests })} | 
    ${t("bookingDetails.bedrooms", { count: booking.numBedrooms })} | 
    ${t("bookingDetails.beds", { count: booking.numBeds })} | 
    ${t("bookingDetails.baths", { count: booking.numBathrooms })}`}
      </p>
      <div className={styles["chat-block"]}>
        <div className={styles["chat-block-element"]}>
          <img
            src={chatIcon}
            alt={t("bookingDetails.chatIconAlt")}
            className={styles["chat-icon"]}
          />
          {/* TODO захордкоженный текст */}
          <span className={styles["chat-text"]}>
            {" "}
            {t("bookingDetails.reviews", { count: 3 })}
          </span>
        </div>
        <p className={styles["chat-block-text"]}>
          {t("bookingDetails.reportListing")}
        </p>
      </div>
    </div>
  );
};

export default BookingDetails;
