import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

interface AuthContextType {
  isAuthenticated: boolean;
  token: string | null;
  setAuthState: (token: string | null) => void;
  checkTokenValidity: () => Promise<void>;
}

const initialState: AuthContextType = {
  isAuthenticated: false,
  token: null,
  setAuthState: () => {},
  checkTokenValidity: async () => {},
};

const AuthContext = createContext<AuthContextType>(initialState);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("authToken")
  );

  const setAuthState = (newToken: string | null) => {
    setToken(newToken);
    if (newToken) {
      localStorage.setItem("authToken", newToken);
    } else {
      localStorage.removeItem("authToken");
    }
  };
  const checkTokenValidity = async () => {
    if (token) {
      const response = await fetch("/api/check-token", {
        //TODO пока нет такого роута на бэке
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200 && response.status !== 201) {
        // TODO Токен невалиден, нужно выполнить действия по его обновлению или выйти из системы
        setAuthState(null);
      }
    }
  };

  useEffect(() => {
    checkTokenValidity(); // Проверка токена при старте приложения
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!token,
        token,
        setAuthState,
        checkTokenValidity,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
