import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./stickyBanner.module.scss";
import leftHeader from "../../assets/images/header_left.png";

interface IStickyBannerProps {
  onCheckAvailability: () => void;
  pricePerNight: number | undefined;
}

const StickyBanner: React.FC<IStickyBannerProps> = ({
  onCheckAvailability,
  pricePerNight,
}) => {
  const { t } = useTranslation();

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -102;
      const yPosition =
        section.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({
        top: yPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <header className={`${styles.header} ${styles.sticky}`}>
      <div className={styles["header-content"]}>
        <div className={styles["header-left"]}>
          <div className={styles["sticky-navigation"]}>
            <img
              className={styles.leftHeaderIcon}
              src={leftHeader}
              alt="icon"
            />
            <nav>
              <button onClick={() => scrollToSection("photos")}>
                {t("header.Photos")}
              </button>
              <button onClick={() => scrollToSection("amenities")}>
                {t("header.Amenities")}
              </button>
              <button onClick={() => scrollToSection("reviews")}>
                {t("header.Reviews")}
              </button>
              <button onClick={() => scrollToSection("location")}>
                {t("header.Location")}
              </button>
            </nav>
          </div>
        </div>
        <div className={styles["header-right-sticky"]}>
          <div className={styles["price-block"]}>
            {pricePerNight ? (
              <p className={styles.price}>
                ${pricePerNight}&nbsp;{t("header.night")}
              </p>
            ) : (
              ""
            )}
            <p className={styles["header-reviews"]}>12 {t("header.reviews")}</p>
            <button
              className={styles["check-dates-btn"]}
              onClick={onCheckAvailability}
            >
              {t("header.Check-available-dates")}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default StickyBanner;
