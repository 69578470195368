import React, { useState } from "react";
import styles from "./placeFeatures.module.scss";
import { Feature } from "../../types/feature";
import kitchenIcon from "../../assets/images/icon-features/kitchen.png";
import parkingIcon from "../../assets/images/icon-features/parking.png";
import tvIcon from "../../assets/images/icon-features/tv.png";
import washerIcon from "../../assets/images/icon-features/washer.png";
import balconyIcon from "../../assets/images/icon-features/balcony.png";
import wifiIcon from "../../assets/images/icon-features/wifi.png";
import poolIcon from "../../assets/images/icon-features/pool.png";
import elevatorIcon from "../../assets/images/icon-features/lift.png";
import airConditionerIcon from "../../assets/images/icon-features/snowflake.png";
import carbonMonoxideIcon from "../../assets/images/icon-features/carbon.png";
import { useTranslation } from "react-i18next";

const CATEGORY_ORDER = [
  "Entertainment",
  "Accessibility",
  "Bedroom and laundry",
  "Bathroom",
  "Internet and office",
  "Kitchen and dining",
  "Parking and facilities",
  "Scenic views",
  "Heating and cooling",
  "Outdoor",
  "Services",
  "Family features",
  "Location features",
  "Pets",
  "Food and drink",
  "Activities",
  "Not included",
];

interface PlaceFeaturesProps {
  features: Feature[];
}

const getIconBySemanticId = (semanticId: string): string => {
  switch (semanticId) {
    case "kitchen":
      return kitchenIcon;
    case "parking":
      return parkingIcon;
    case "tv":
      return tvIcon;
    case "washer":
      return washerIcon;
    case "balcony":
      return balconyIcon;
    case "wifi":
      return wifiIcon;
    case "pool":
      return poolIcon;
    case "elevator":
      return elevatorIcon;
    case "air_conditioner":
      return airConditionerIcon;
    case "carbon_monoxide":
      return carbonMonoxideIcon;
    default:
      return balconyIcon; // Иконка по умолчанию
  }
};

const PlaceFeatures: React.FC<PlaceFeaturesProps> = ({ features }) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const maxVisibleFeatures = 2;

  const toggleShowAll = () => {
    setShowAll((prev) => !prev);
  };

  const groupedFeatures = features.reduce((acc, feature) => {
    const category = feature.category?.name || "Unknown";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(feature);
    return acc;
  }, {} as Record<string, Feature[]>);

  const sortedCategories = Object.keys(groupedFeatures).sort((a, b) => {
    const indexA = CATEGORY_ORDER.indexOf(a);
    const indexB = CATEGORY_ORDER.indexOf(b);

    if (indexA === -1) return 1; // Неизвестные категории в конец
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

  // Ограничиваем общее число отображаемых фич
  const visibleFeatures: Feature[] = [];
  let currentCount = 0;

  for (const category of sortedCategories) {
    const featuresInCategory = groupedFeatures[category];
    for (const feature of featuresInCategory) {
      if (currentCount >= maxVisibleFeatures && !showAll) break;
      visibleFeatures.push(feature);
      currentCount++;
    }
    if (currentCount >= maxVisibleFeatures && !showAll) break;
  }

  return (
    <div className={styles["place-features"]} id="amenities">
      <h2 className={styles["place-features-heading"]}>
        {t("placeFeatures.heading")}
      </h2>

      {/* Список удобств */}
      <ul className={styles["place-features-list"]}>
        {visibleFeatures.map((feature) => (
          <li
            key={feature["@id"]}
            className={styles["place-feature-item"]}
            style={
              feature.category.name === "Not included"
                ? { opacity: 0.2 }
                : undefined
            }
          >
            <img
              src={getIconBySemanticId(feature.semanticId)}
              alt={feature.name}
              className={styles["place-feature-icon"]}
            />
            <span className={styles["place-feature-name"]}>{feature.name}</span>
          </li>
        ))}
      </ul>

      {/* Кнопка "Показать больше" */}

      <button
        className={styles["place-features-toggle"]}
        onClick={toggleShowAll}
      >
        {showAll
          ? t("placeFeatures.showLess")
          : t("placeFeatures.showAll", { count: features.length })}
      </button>
    </div>
  );
};

export default PlaceFeatures;
