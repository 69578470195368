import React, { useState } from "react";
import styles from "./card.module.scss";
import { useTranslation } from "react-i18next";
import { Booking } from "../../types/booking";
import heartIcon from "../../assets/images/icon-for-search/heart.png";
import starIcon from "../../assets/images/icon-for-search/star.png";
import { useNavigate } from "react-router-dom";

interface CardProps {
  booking: Booking;
}

const Card: React.FC<CardProps> = ({ booking }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const photos = booking.objectPhotos ? booking.objectPhotos.slice(0, 5) : [];
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  const handlePhotoChange = (index: number) => {
    setCurrentPhotoIndex(index);
  };

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Если клик произошел на индикаторе фото, прерываем переход
    if ((e.target as HTMLElement).classList.contains(styles.photoIndicator)) {
      return;
    }

    // Переход на страницу с деталями бронирования
    navigate(`/booking/${booking.id}`);
  };

  return (
    <div className={styles.card} onClick={handleCardClick}>
      <div className={styles.imageContainer}>
        {photos.length > 0 ? (
          <>
            <img
              src={photos[currentPhotoIndex].storageUrl}
              alt={booking.name}
              className={styles.image}
            />
            <div className={styles.photoSlider}>
              {photos.map((_, index) => (
                <div
                  key={index}
                  className={`${styles.photoIndicator} ${
                    index === currentPhotoIndex ? styles.active : ""
                  }`}
                  onClick={() => handlePhotoChange(index)}
                ></div>
              ))}
            </div>
          </>
        ) : (
          <div className={styles.noImage}>{t("card.noPhotos")}</div>
        )}
        <div className={styles.rating}>
          <img src={starIcon} alt="icon star" className={styles.starIcon} />
          <span>4.5</span>
        </div>
        <button className={styles.likeButton}>
          <img src={heartIcon} alt="icon heart" className={styles.heartIcon} />
        </button>
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <h3>{booking.name}</h3>
          <p className={styles["parameters-block"]}>
            {`${t("card.beds", { count: booking.numBeds })} | 
        ${t("card.bedrooms", { count: booking.numBedrooms })}`}
          </p>
        </div>
        <div className={styles.footer}>
          <span className={styles.price}>$1 345</span>
          <span className={styles.night}>214$/night</span>
        </div>
      </div>
    </div>
  );
};

export default Card;
