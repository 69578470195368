import axios from "axios";
import { ISearchSuggestionItem } from "../types/searchSuggestionItem";

const API = axios.create({
  baseURL: "https://app-staging.selof.ru/api/v1",
});

// Основная информация об апартаментах
export const fetchBooking = (id: string) => API.get(`object/rentals/${id}`);

// Доступные даты для календаря
export const getAvailableNights = (id: string) =>
  API.get(`/booking/rentals/${id}/nights`);

// Хост (владелец апартаментов)
export const fetchHost = (participantId: string) =>
  API.get(`/profile/participants/${participantId}`);

// Занятость апартаментов
export const fetchOccupancies = (id: string) =>
  API.get(`/booking/rentals/${id}/occupancies`);

// Правила и ограничения
export const fetchRestrictions = (id: string) =>
  API.get(`/object/restrictions?filter[rental]=${id}`);

export const getAddressFromCoordinates = async (
  latitude: number,
  longitude: number,
  language: string
): Promise<string | null> => {
  try {
    const response = await axios.get(
      "https://nominatim.openstreetmap.org/reverse",
      {
        params: {
          lat: latitude,
          lon: longitude,
          format: "json",
          "accept-language": language,
        },
      }
    );
    return response.data.display_name; // Возвращает полный адрес
  } catch (error) {
    console.error("Ошибка при геокодировании:", error);
    return null; // В случае ошибки
  }
};

export const getFullPrice = async (
  id: string,
  from: string,
  nights: number,
  numGuests: number
) =>
  API.get("/search/rentals", {
    params: {
      id,
      "book[from]": from,
      "book[nights]": nights,
      "numGuests[gte]": numGuests,
    },
  });

export const checkAvailability = async (id: string) =>
  API.get(`/booking/rentals/${id}/availability`, {});

export const fetchSearchResults = async ({
  lat,
  lon,
  distance,
  page = "1",
  from,
  nights,
  kind,
  numberBedrooms,
  numberBeds,
  numberBathrooms,
}: {
  lat?: string;
  lon?: string;
  distance?: string;
  page?: string;
  from?: string;
  nights?: string;
  kind?: string;
  numberBedrooms?: string;
  numberBeds?: string;
  numberBathrooms?: string;
}) => {
  try {
    // Формируем параметры запроса
    const params: Record<string, string | number> = {
      page,
    };

    if (lat) {
      params["geoPoint[lat]"] = lat;
    }
    if (lon) {
      params["geoPoint[lon]"] = lon;
    }
    if (distance) {
      params["geoPoint[distance]"] = distance;
    }
    if (from) {
      params["book[from]"] = from;
    }
    if (nights) {
      params["book[nights]"] = nights;
    }
    if (kind) {
      params["kind"] = kind;
    }
    if (numberBedrooms) {
      params["numBedrooms[gte]"] = numberBedrooms;
    }
    if (numberBeds) {
      params["numBeds[gte]"] = numberBeds;
    }
    if (numberBathrooms) {
      params["numBathrooms[gte]"] = numberBathrooms;
    }
    const response = await API.get("/search/rentals", { params });
    return response.data;
  } catch (error) {
    console.error("Ошибка при выполнении поиска:", error);
    throw error;
  }
};

interface IGoogleSignInResponse {
  signInResult: {
    roles: string[];
    isSuccessful: boolean;
    accessToken: string;
  };

  [key: string]: any;
}

export const fetchGoogleSignIn = (credential: string) =>
  API.post<IGoogleSignInResponse>(
    "/auth/google-sign-in",
    { credential },
    {
      headers: {
        "Content-Type": "application/ld+json",
      },
    }
  );

interface ISearchSuggestions {
  "hydra:totalItems": number;
  "hydra:member": ISearchSuggestionItem[];
}

export const fetchSearchSuggestions = async (
  query: string,
  language: string
) => {
  const response = await API.get<ISearchSuggestions>(
    "/geo/search-suggestions",
    {
      params: {
        query,
        resultLanguage: language,
      },
    }
  );
  return response.data;
};
