import React, { useEffect, useRef, useState } from "react";
import { DayPicker, DateRange } from "react-day-picker";
import styles from "./rightStickyContent.module.scss";
import arrowDownIcon from "../../assets/images/arrow_down.png";
import minusIcon from "../../assets/images/minus.png";
import plusIcon from "../../assets/images/plus.png";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { enUS, ru } from "date-fns/locale";
import { useNotification } from "../../context/notificationContext";

interface RightStickyContentProps {
  price: number | undefined;
  pricePerNight: number | undefined;
  maxGuests: number;
  selectedDates: { checkIn: Date | undefined; checkOut: Date | undefined };
  availableDates: string[];
  onDateChange: (
    checkInDate: Date | undefined,
    checkOutDate: Date | undefined
  ) => void;
  setGuestCount: (type: string, increment: boolean) => void;
  guestCount: {
    adults: number;
    children: number;
    infants: number;
    pets: number;
  };
  onCheckAvailability: () => void;
}

const RightStickyContent: React.FC<RightStickyContentProps> = ({
  price,
  pricePerNight,
  maxGuests,
  selectedDates,
  availableDates,
  onDateChange,
  setGuestCount,
  guestCount,
  onCheckAvailability,
}) => {
  const { showNotification } = useNotification();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isGuestDropdownOpen, setIsGuestDropdownOpen] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language === "ru" ? ru : enUS;

  const totalGuests = guestCount.adults + guestCount.children;
  const toggleGuestDropdown = () => {
    setIsGuestDropdownOpen((prev) => !prev);
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen((prev) => !prev);
  };

  // Преобразуем доступные даты в Set для быстрой проверки
  const availableDatesSet = new Set(
    availableDates.map((date) => date.toString())
  );

  const handleDateChange = (range: DateRange | undefined) => {
    if (!range || !range.from || !range.to) {
      onDateChange(undefined, undefined);
      return;
    }

    // Проверяем, что все даты в диапазоне доступны
    const rangeIsValid = Array.from(
      {
        length:
          (range.to.getTime() - range.from.getTime()) / (1000 * 60 * 60 * 24) +
          1,
      },
      (_, i) => {
        //@ts-ignore
        const date = new Date(range.from.getTime() + i * 24 * 60 * 60 * 1000);
        return format(date, "yyyyMMdd"); // Преобразуем в формат YYYYMMDD
      }
    ).every((dateString) => availableDatesSet.has(dateString));

    if (rangeIsValid) {
      onDateChange(range.from, range.to);
    } else {
      showNotification(t("rightStickyContent.wrongPeriod"), "warning");
      onDateChange(undefined, undefined);
    }
  };

  const handleResetDates = () => {
    onDateChange(undefined, undefined);
  };

  const handleGuestChange = (type: string, increment: boolean) => {
    setGuestCount(type, increment);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target as Node)
    ) {
      setIsDatePickerOpen(false);
    }
  };

  useEffect(() => {
    if (isDatePickerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDatePickerOpen]);

  return (
    <div className={styles["right-sticky-content"]}>
      <div className={styles["sticky-block"]}>
        <div className={styles["price-info"]}>
          <h2>${price ? price : ""}</h2>
          <span>
            {pricePerNight ? pricePerNight : ""}$/
            {t("rightStickyContent.night")}
          </span>
        </div>
        <div className={styles["date-inputs"]} onClick={toggleDatePicker}>
          <div className={styles["date-input"]}>
            <span className={styles["date-input-label"]}>
              {t("rightStickyContent.Check-in")}
            </span>
            <span className={styles["date-input-placeholder"]}>
              {selectedDates.checkIn
                ? selectedDates.checkIn.toLocaleDateString()
                : t("rightStickyContent.Please-enter-a-date")}
            </span>
          </div>
          <div className={styles["date-input"]}>
            <span className={styles["date-input-label"]}>
              {t("rightStickyContent.Check-out")}
            </span>
            <span className={styles["date-input-placeholder"]}>
              {selectedDates.checkOut
                ? selectedDates.checkOut.toLocaleDateString()
                : t("rightStickyContent.Please-enter-a-date")}
            </span>
          </div>
        </div>
        {/* Date Picker */}
        {isDatePickerOpen && (
          <div className={styles["date-picker"]} ref={datePickerRef}>
            <DayPicker
              mode="range"
              selected={{
                from: selectedDates.checkIn,
                to: selectedDates.checkOut,
              }}
              onSelect={handleDateChange}
              disabled={[
                {
                  before: new Date(),
                },
                (date) => {
                  // Делаем недоступными все даты, которые не входят в доступные
                  const formattedDate = format(date, "yyyyMMdd");
                  return !availableDatesSet.has(formattedDate);
                },
              ]}
              locale={currentLocale}
              formatters={{
                formatCaption: (date) =>
                  format(date, "LLLL yyyy", { locale: currentLocale }),
              }}
              numberOfMonths={1}
            />
            <button
              onClick={handleResetDates}
              className={styles["reset-dates-button"]}
            >
              {t("rightStickyContent.Reset-Dates")}
            </button>
          </div>
        )}
        <div className={styles["guest-dropdown"]}>
          <button onClick={toggleGuestDropdown}>
            <div className={styles["guest-dropdown-left"]}>
              <span className={styles["guest-dropdown-placeholder"]}>
                {t("rightStickyContent.Guests")}
              </span>
              <span className={styles["guest-label"]}>
                {t("rightStickyContent.guests", { count: totalGuests })}
              </span>
            </div>
            <img
              src={arrowDownIcon}
              alt="Arrow Down"
              className={styles["dropdown-icon"]}
            />
          </button>
          {isGuestDropdownOpen && (
            <div className={styles["guest-dropdown-menu"]}>
              {["adults", "children", "infants", "pets"].map((type, index) => (
                <div key={index} className={styles["guest-item"]}>
                  <div className={styles["guest-labels"]}>
                    <span className={styles["guest-title"]}>
                      {t(`rightStickyContent.${type}.title`)}
                    </span>
                    <span className={styles["guest-subtitle"]}>
                      {t(`rightStickyContent.${type}.subtitle`)}
                    </span>
                  </div>
                  <div className={styles["guest-controls"]}>
                    <button
                      onClick={() => handleGuestChange(type, false)}
                      disabled={
                        guestCount[type as keyof typeof guestCount] === 0
                      }
                    >
                      <img
                        src={minusIcon}
                        alt={t("rightStickyContent.minusIconAlt")}
                        className={styles["minus-icon"]}
                      />
                    </button>
                    <span>{guestCount[type as keyof typeof guestCount]}</span>
                    <button onClick={() => handleGuestChange(type, true)}>
                      <img
                        src={plusIcon}
                        alt={t("rightStickyContent.plusIconAlt")}
                        className={styles["plus-icon"]}
                      />
                    </button>
                  </div>
                </div>
              ))}
              <p>
                {t("rightStickyContent.maxGuestsMessage", {
                  maxGuests,
                })}
              </p>
              <button
                onClick={toggleGuestDropdown}
                className={styles["close-button"]}
              >
                {t("rightStickyContent.close")}
              </button>
            </div>
          )}
        </div>
        <button
          className={styles["availability-button"]}
          onClick={onCheckAvailability}
        >
          {t("rightStickyContent.checkAvailableDates")}
        </button>
      </div>
    </div>
  );
};

export default RightStickyContent;
