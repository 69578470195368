import { useEffect, useState } from "react";
import { DayPicker, DateRange } from "react-day-picker";
import { addMonths, format, Locale, startOfMonth } from "date-fns";
import styles from "./twoCalendarsRangePicker.module.scss";

interface IProps {
  checkIn?: Date;
  checkOut?: Date;
  onDateChange: (checkIn?: Date, checkOut?: Date) => void;
  allowAllDates: boolean;
  availableDatesSet: Set<string>;
  currentLocale: Locale;
  // любые другие пропсы, которые вам нужны
}

export function TwoCalendarsRangePicker({
  checkIn,
  checkOut,
  onDateChange,
  allowAllDates,
  availableDatesSet,
  currentLocale,
}: IProps) {
  // Левый и правый месяц, которые действительно рендерятся
  const [leftMonth, setLeftMonth] = useState<Date>(
    startOfMonth(new Date()) // по умолчанию - текущий
  );
  const [rightMonth, setRightMonth] = useState<Date>(
    addMonths(startOfMonth(new Date()), 1) // по умолчанию +1 месяц
  );

  // Когда меняется checkIn (например, пользователь выбрал новую дату),
  // перенастраиваем месяцы:
  useEffect(() => {
    if (checkIn) {
      const monthOfCheckIn = startOfMonth(checkIn);
      setLeftMonth(monthOfCheckIn);
      setRightMonth(addMonths(monthOfCheckIn, 1));
    } else {
      // Если диапазон сброшен (нет checkIn),
      // возвращаемся к текущему месяцу + следующий:
      setLeftMonth(startOfMonth(new Date()));
      setRightMonth(addMonths(startOfMonth(new Date()), 1));
    }
  }, []);

  // Обработчик выбора дат (диапазона)
  const handleSelect = (range: DateRange | undefined) => {
    if (!range) {
      onDateChange(undefined, undefined);
      return;
    }
    onDateChange(range.from, range.to);
  };

  // Пользователь перелистывает месяц в левом календаре:
  const handleLeftMonthChange = (newMonth: Date) => {
    setLeftMonth(newMonth);
  };

  // Пользователь перелистывает месяц в правом календаре:
  const handleRightMonthChange = (newMonth: Date) => {
    setRightMonth(newMonth);
  };

  return (
    <div className={styles.dayPickers}>
      {/* Левый календарь */}
      <DayPicker
        mode="range"
        month={leftMonth} // какой месяц показать
        onMonthChange={handleLeftMonthChange}
        selected={{ from: checkIn, to: checkOut }}
        onSelect={handleSelect}
        numberOfMonths={1}
        disabled={[
          {
            before: new Date(),
          },
          (date) => {
            if (allowAllDates) return false; // Если все даты разрешены, не блокируем
            const formattedDate = format(date, "yyyyMMdd");
            return !availableDatesSet.has(formattedDate);
          },
        ]}
        locale={currentLocale}
        formatters={{
          formatCaption: (date) =>
            format(date, "LLLL yyyy", { locale: currentLocale }),
        }}
      />

      {/* Правый календарь */}
      <div className={styles.secondCalendar}>
        <DayPicker
          mode="range"
          month={rightMonth} // какой месяц показать
          onMonthChange={handleRightMonthChange}
          selected={{ from: checkIn, to: checkOut }}
          onSelect={handleSelect}
          numberOfMonths={1}
          disabled={[
            {
              before: new Date(),
            },
            (date) => {
              if (allowAllDates) return false; // Если все даты разрешены, не блокируем
              const formattedDate = format(date, "yyyyMMdd");
              return !availableDatesSet.has(formattedDate);
            },
          ]}
          locale={currentLocale}
          formatters={{
            formatCaption: (date) =>
              format(date, "LLLL yyyy", { locale: currentLocale }),
          }}
        />
      </div>
    </div>
  );
}
