import React from "react";
import styles from "./photoPreview.module.scss";
import { Photo } from "../../types/photo";
import { useTranslation } from "react-i18next";

interface PhotoPreviewProps {
  photos: Photo[];
  onShowAll: () => void; // Функция для открытия полной галереи
}

const PhotoPreview: React.FC<PhotoPreviewProps> = ({ photos, onShowAll }) => {
  const { t } = useTranslation();
  const maxVisiblePhotos = 5; // Максимальное количество отображаемых фотографий

  return (
    <div className={styles["photo-preview"]} id="photos">
      {/* Первая фотография крупно */}
      {photos.length > 0 && (
        <div className={styles["photo-preview-main"]}>
          <img
            src={photos[0].storageUrl}
            alt={t("photoPreview.mainPhotoAlt")}
          />
        </div>
      )}

      {/* Сетка дополнительных фотографий */}
      <div className={styles["photo-preview-grid"]}>
        {photos.slice(1, maxVisiblePhotos).map((photo, index) => (
          <div key={index} className={styles["photo-preview-item"]}>
            <img
              src={photo.storageUrl}
              alt={t("photoPreview.previewAlt", { index: index + 1 })}
            />
          </div>
        ))}

        {/* Кнопка "Show all photos" */}
        {photos.length > maxVisiblePhotos && (
          <div className={styles["photo-preview-overlay"]} onClick={onShowAll}>
            <span>
              {" "}
              {t("photoPreview.showAllPhotos", {
                count: photos.length - maxVisiblePhotos,
              })}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoPreview;
