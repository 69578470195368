import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Booking } from "../../types/booking";
import LeftContent from "../../components/leftContent/leftContent";
import RightStickyContent from "../../components/rightStickyContent/rightStickyContent";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { checkAvailability, fetchBooking, getFullPrice } from "../../api/api";
import shareIcon from "../../assets/images/share_icon.png";
import favoriteIcon from "../../assets/images/favorite_icon.png";
import styles from "./bookingPage.module.scss";
import Reviews from "../../components/reviews/reviews";
import Map from "../../components/map/map";
import { Review } from "../../types/review";
import derekAvatar from "../../assets/images/derek.png";
import tomAvatar from "../../assets/images/tom.png";
import lizaAvatar from "../../assets/images/liza.png";
import HostDetails from "../../components/hostDetails/hostDetails";
import iconAnn from "../../assets/images/ann.png";
import { useTranslation } from "react-i18next";
import { calculateNights, formatDate } from "../../utils/helpers";
import StickyBanner from "../../components/stickyBanner/stickyBanner";
import { useNotification } from "../../context/notificationContext";

const reviews: Review[] = [
  // TODO в юудущем эти данные будут приходить с бэкенда
  {
    name: "Derek",
    avatar: derekAvatar,
    rating: 4.5,
    date: "September 2023",
    location: "11 months on Hofowo",
    text: "Great place, like advertised. Host was very responsive. Nice and quiet but close to everything within walking distance. Recommend it.",
  },
  {
    name: "Tom",
    avatar: tomAvatar,
    rating: 4.5,
    date: "September 2023",
    location: "11 months on Hofowo",
    text: "Great place, like advertised. Host was very responsive. Nice and quiet but close to everything within walking distance. Recommend it.",
  },
  {
    name: "Liza",
    avatar: lizaAvatar,
    rating: 4.5,
    date: "September 2023",
    location: "11 months on Hofowo",
    text: "Great place, like advertised. Host was very responsive. Nice and quiet but close to everything within walking distance. Recommend it.",
  },
];

const BookingPage: React.FC = () => {
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [booking, setBooking] = useState<Booking | null>(null);
  const [location, setLocation] = useState("Istanbul");
  const [guestCount, setGuestCount] = useState({
    adults: 1,
    children: 0,
    infants: 0,
    pets: 0,
  });
  const [maxGuests, setMaxGuests] = useState(1);
  const [selectedDates, setSelectedDates] = useState<{
    checkIn: Date | undefined;
    checkOut: Date | undefined;
  }>({
    checkIn: undefined,
    checkOut: undefined,
  });

  const [price, setPrice] = useState<number | undefined>(undefined);
  const [pricePerNight, setPricePerNight] = useState<number | undefined>(
    undefined
  );
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [isStickyVisible, setIsStickyVisible] = useState(false);

  const handleScroll = () => {
    setIsStickyVisible(window.scrollY > 130);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleGuestLimitExceeded = () => {
    showNotification(t("bookingPage.guestLimitExceeded"), "warning");
  };

  const handleLocationChange = (value: string) => {
    setLocation(value);
  };

  const handleGuestChange = (type: string, increment: boolean) => {
    setGuestCount((prev) => {
      // Рассчитываем текущее количество основных гостей (взрослые + дети)
      const totalGuests = prev.adults + prev.children;

      // Если пытаемся увеличить количество основных гостей сверх maxGuests, показываем уведомление
      if (
        increment &&
        totalGuests >= maxGuests &&
        (type === "adults" || type === "children")
      ) {
        handleGuestLimitExceeded();
        return prev;
      }

      // Рассчитываем новое количество для конкретного типа гостей
      const newCount = increment
        ? prev[type as keyof typeof guestCount] + 1
        : prev[type as keyof typeof guestCount] - 1;

      // Возвращаем обновленное состояние, не допуская отрицательных значений
      return {
        ...prev,
        [type]: Math.max(0, newCount),
      };
    });
  };

  const fetchBookingData = async () => {
    try {
      const response = await fetchBooking(id!);
      setBooking({
        ...response.data,
        location: {
          latitude: response.data.geoPoint.lat,
          longitude: response.data.geoPoint.lon,
        },
        id,
      });
      if (response.data.numGuests) {
        setMaxGuests(response.data.numGuests);
      }
    } catch (error) {
      console.error("Error fetching booking:", error);
    }
  };

  const fetchAvailabilityData = async () => {
    try {
      const response = await checkAvailability(id!);
      const dates = response.data["hydra:member"];
      if (Array.isArray(dates)) {
        setAvailableDates(dates);
      } else {
        setAvailableDates([]);
      }
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchBookingData();
      fetchAvailabilityData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchPriceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates, guestCount, id]);

  const fetchPriceData = async () => {
    if (!selectedDates.checkIn || !selectedDates.checkOut) return;

    const nights = calculateNights(
      selectedDates.checkIn,
      selectedDates.checkOut
    );
    const formattedDate = formatDate(selectedDates.checkIn);

    try {
      const data = await getFullPrice(
        id!,
        formattedDate,
        nights,
        guestCount.adults + guestCount.children
      );

      if (data.data["hydra:member"]?.length > 0) {
        const rentalCost = data.data["hydra:member"][0].rentalCost;
        setPrice(rentalCost);
        setPricePerNight(parseFloat((rentalCost / nights).toFixed(2)));
      } else {
        setPrice(undefined);
        setPricePerNight(undefined);
      }
    } catch (error) {
      console.error("Error fetching availability:", error);
      setPrice(undefined);
      setPricePerNight(undefined);
    }
  };

  if (!booking) return <div>{t("bookingPage.loading")}</div>;

  const handleCheckAvailability = async () => {
    if (!selectedDates.checkIn || !selectedDates.checkOut) {
      showNotification(t("bookingPage.selectDates"), "warning");
      return;
    }
    if (guestCount.adults + guestCount.children === 0) {
      showNotification(t("bookingPage.addGuests"), "warning");
      return;
    }
    try {
      //TODO Переходим на другую страницу
      showNotification(t("bookingPage.availabilityChecked"), "success");
    } catch (error) {
      console.error("Error checking availability:", error);
      showNotification(t("bookingPage.availabilityError"), "error");
    }
  };

  return (
    <div className={styles["booking-page"]}>
      <Header
        availableDates={availableDates}
        onDateChange={(checkInDate, checkOutDate) => {
          setSelectedDates({
            checkIn: checkInDate,
            checkOut: checkOutDate,
          });
        }}
        location={location}
        onLocationChange={handleLocationChange}
        checkIn={selectedDates.checkIn}
        checkOut={selectedDates.checkOut}
        adults={guestCount.adults}
        children={guestCount.children}
      />
      {isStickyVisible && (
        <StickyBanner
          onCheckAvailability={handleCheckAvailability}
          pricePerNight={pricePerNight}
        />
      )}
      <div className={styles["title-block"]}>
        <h2 className={styles["title-booking"]}>{booking.name}</h2>
        <div className={styles["title-block-right"]}>
          <div className={styles["block-share"]}>
            <img
              src={shareIcon}
              alt={t("bookingPage.shareIconAlt")}
              className={styles["share-icon"]}
            />
            <span className={styles["share-text"]}>
              {t("bookingPage.share")}
            </span>
          </div>
          <div className={styles["favorite-block"]}>
            <img
              src={favoriteIcon}
              alt={t("bookingPage.favoriteIconAlt")}
              className={styles["favorite-icon"]}
            />
            <span className={styles["favorite-text"]}>
              {t("bookingPage.toFavorites")}
            </span>
          </div>
        </div>
      </div>
      <div className={styles["content"]}>
        <div className={styles["left"]}>
          <LeftContent
            availableDates={availableDates}
            booking={booking}
            selectedDates={selectedDates}
            onDateChange={(checkInDate, checkOutDate) => {
              setSelectedDates({
                checkIn: checkInDate,
                checkOut: checkOutDate,
              });
            }}
          />
        </div>
        <div className={styles["right"]}>
          {/* TODO в будущем данные должны приходить с бэкенда */}
          <RightStickyContent
            availableDates={availableDates}
            guestCount={guestCount}
            setGuestCount={handleGuestChange}
            pricePerNight={pricePerNight}
            price={price}
            maxGuests={maxGuests}
            selectedDates={selectedDates}
            onDateChange={(checkInDate, checkOutDate) => {
              setSelectedDates({
                checkIn: checkInDate,
                checkOut: checkOutDate,
              });
            }}
            onCheckAvailability={handleCheckAvailability}
          />
        </div>
      </div>
      <Reviews reviews={reviews} />
      <Map lat={booking.geoPoint.lat} lon={booking.geoPoint.lon}/>
      {/* TODO в будущем данные должны приходить с бэкенда */}
      <HostDetails
        name="Ann"
        joinedDate="August 2019"
        verified={true}
        reviewsCount={63}
        bio="Good day! What is important to know about us? So this is what we are people. We try to make the life of our clients happier, and the rest in Phuket more comfortable. We care about the interests of our guests, help them in everything, advise."
        languages={["English", "Русский"]}
        responseRate="91%"
        responseTime="within an hour"
        avatarUrl={iconAnn}
      />
      <Footer booking={booking} />
    </div>
  );
};

export default BookingPage;
