import React from "react";
import ReactSlider from "react-slider";
import styles from "./radiusModal.module.scss";
import { useTranslation } from "react-i18next";

interface RadiusModalProps {
  radius: number;
  onChange: (value: number) => void;
}

const RadiusModal: React.FC<RadiusModalProps> = ({ radius, onChange }) => {
  const { t } = useTranslation();
  const step = 2.62; // размер смещения в пикселях при изменении радиуса на одну единицу

  const calculatePosition = (radius: number) => {
    const position = (radius - 5) * step + 20;
    return position;
  };
  return (
    <div className={styles.modal}>
      <div className={styles.content}>
        <div className={styles.sliderWrapper}>
          <ReactSlider
            value={radius}
            min={5}
            max={100}
            step={1}
            onChange={(newValue) => onChange(newValue)}
            className={styles.slider}
            thumbClassName={styles.thumb}
            trackClassName={styles.track}
            renderTrack={(props, state) => {
              // Позиционирование для левого и правого трека
              const trackStyle =
                state.index === 0 ? styles.trackLeft : styles.trackRight;
              return (
                <div {...props} className={`${styles.track} ${trackStyle}`} />
              );
            }}
          />
          <div
            className={styles.value}
            style={{
              left: `${calculatePosition(radius)}px`,
              transform: "translateX(-50%)",
            }}
            /* Позиционируем значение относительно слайдера */
          >
            <span>
              {radius} {t("radiusModal.km")}
            </span>
          </div>
        </div>
        <div className={styles.minMax}>
          <p className={styles.text}>5{t("radiusModal.km")}</p>
          <p className={styles.text}>100+{t("radiusModal.km")}</p>
        </div>
      </div>
    </div>
  );
};

export default RadiusModal;
