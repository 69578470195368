import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotificationProvider from "./context/notificationContext";
import NotFoundPage from "./pages/notFoundPage/notFoundPage";
import BookingPage from "./pages/bookingPage/bookingPage";
import "./App.css";
import SearchResult from "./pages/searchResult/searchResult";
import MainPage from "./pages/mainPage/mainPage";
import { AuthProvider } from "./context/authContext";
import ProfilePage from "./pages/profilePage/profilePage";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <MainPage />,
    },
    {
      path: "/booking/:id",
      element: <BookingPage />,
    },
    {
      path: "/search",
      element: <SearchResult />,
    },
    {
      path: "/profile",
      element: <ProfilePage />,
    },
    {
      path: "/*",
      element: <NotFoundPage />,
    },
  ],
  {
    future: {
      v7_startTransition: true,
    },
  }
);

const App: React.FC = () => (
  <div className="app-container">
    <AuthProvider>
      <NotificationProvider>
        <RouterProvider router={router} />
      </NotificationProvider>
    </AuthProvider>
  </div>
);

export default App;
