import { FC } from "react";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import styles from "./suggestionsModal.module.scss";
import imageIcon from "../../assets/images/location-marker-blue.png";

interface ISuggestionsModal {
  locationSuggestions: ISearchSuggestionItem[];
  onSelectSuggestion: (suggestion: ISearchSuggestionItem) => void;
}

const SuggestionsModal: FC<ISuggestionsModal> = ({
  locationSuggestions,
  onSelectSuggestion,
}) => {
  return (
    <div className={styles.modal}>
      {locationSuggestions.length > 0 && (
        <div className={styles.content}>
          {locationSuggestions.map((item) => (
            <div
              key={item["@id"]}
              className={styles.suggestionItem}
              onClick={() => onSelectSuggestion(item)}
            >
              <img
                className={styles.image}
                src={imageIcon}
                alt="icon location"
              />
              <p className={styles.text}>{item.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SuggestionsModal;
