import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import styles from "./filterModal.module.scss";
import closeIcon from "../../assets/images/icon-for-search/x.png";
import Slider from "react-slider";
import PriceChart from "../priceChart/priceChart";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchSearchResults } from "../../api/api";
import { applyFilterValue, getRoomFilterValue } from "../../utils/helpers";
import { useQueryParams } from "../../hooks/useQueryParams";

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FilterModal: React.FC<FilterModalProps> = ({ isOpen, onClose }) => {
  const queryParams = useQueryParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [minPrice, setMinPrice] = useState(1);
  const [maxPrice, setMaxPrice] = useState(35000);
  // Используем useRef для хранения актуальных значений
  const priceRef = useRef({ minPrice, maxPrice });

  useEffect(() => {
    priceRef.current = { minPrice, maxPrice };
  }, [minPrice, maxPrice]);
  const [totalOffers, setTotalOffers] = useState<number>(0);
  const [selectedType, setSelectedType] = useState<string>("hotel");
  const [selectedBedrooms, setSelectedBedrooms] = useState<string>("Any");
  const [selectedBeds, setSelectedBeds] = useState<string>("Any");
  const [selectedBathrooms, setSelectedBathrooms] = useState<string>("Any");

  const currencySymbol = "$"; //TODO в будузем возможно будет несколько валют
  const formatInputValue = (value: number, isMax?: boolean) => {
    const formattedValue = value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return isMax
      ? `${currencySymbol} ${formattedValue}+`
      : `${currencySymbol} ${formattedValue}`;
  };

  const handleMinPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value.replace(/[^0-9]/g, ""), 10);
    if (!isNaN(value) && value <= maxPrice) {
      setMinPrice(value);
    }
  };

  const handleMaxPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value.replace(/[^0-9]/g, ""), 10);
    if (!isNaN(value) && value >= minPrice) {
      setMaxPrice(value);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleTypeSelection = (type: string) => {
    setSelectedType(type);
  };

  const handleRoomSelection = (
    type: "bedrooms" | "beds" | "bathrooms",
    value: string
  ) => {
    if (type === "bedrooms") setSelectedBedrooms(value);
    if (type === "beds") setSelectedBeds(value);
    if (type === "bathrooms") setSelectedBathrooms(value);
  };

  const handleSliderChange = (values: [number, number]) => {
    setMinPrice(values[0]);
    setMaxPrice(values[1]);
    debouncedFetchResults();
  };

  useEffect(() => {
    const allowedValues = ["Any", "1", "2", "3", "4", "5+"];

    const numberBedrooms = queryParams["numBedrooms[gte]"];
    const numberBathrooms = queryParams["numBathrooms[gte]"];
    const numberBeds = queryParams["numBeds[gte]"];
    const type = queryParams.kind;

    if (type) {
      const allowedTypes = ["apartment", "house", "hotel"];
      applyFilterValue(type, allowedTypes, setSelectedType);
    }

    applyFilterValue(numberBeds, allowedValues, setSelectedBeds);
    applyFilterValue(numberBathrooms, allowedValues, setSelectedBathrooms);
    applyFilterValue(numberBedrooms, allowedValues, setSelectedBedrooms);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchResults = async () => {
    const { minPrice, maxPrice } = priceRef.current; //TOD когда бэк сделает параметры для цены - нужно будет их применить
    try {
      const data = await fetchSearchResults({
        lat: queryParams["geoPoint[lat]"],
        lon: queryParams["geoPoint[lon]"],
        distance: queryParams["geoPoint[distance]"],
        from: queryParams["book[from]"],
        nights: queryParams.nights,
        numberBeds: getRoomFilterValue(selectedBeds),
        numberBedrooms: getRoomFilterValue(selectedBedrooms),
        numberBathrooms: getRoomFilterValue(selectedBathrooms),
        kind: selectedType,
      });
      setTotalOffers(data["hydra:totalItems"]);
    } catch (err) {
      console.log("Произошла ошибка при запросе к серверу");
    }
  };

  // Вызываем fetchResults, если меняются значимые параметры
  useEffect(() => {
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, selectedBedrooms, selectedBeds, selectedBathrooms]);

  const handleClear = () => {
    // Сбрасываем значения
    const type = queryParams.kind || "hotel";
    const numberBedrooms = queryParams["numBedrooms[gte]"] || "Any";
    const numberBathrooms = queryParams["numBathrooms[gte]"] || "Any";
    const numberBeds = queryParams["numBeds[gte]"] || "Any";
    const minPrice = parseInt(queryParams["minPrice"] || "1", 10);
    const maxPrice = parseInt(queryParams["maxPrice"] || "35000", 10);

    setSelectedType(type);
    setSelectedBedrooms(numberBedrooms);
    setSelectedBathrooms(numberBathrooms);
    setSelectedBeds(numberBeds);
    setMinPrice(minPrice);
    setMaxPrice(maxPrice);
  };

  // Функция для обработки клика по кнопке Apply
  const handleApply = () => {
    const updatedParams = { ...queryParams };
    // Обновляем параметры URL
    updatedParams.kind = selectedType;

    if (selectedBedrooms !== "Any") {
      updatedParams["numBedrooms[gte]"] = selectedBedrooms;
    } else {
      delete updatedParams["numBedrooms[gte]"];
    }

    if (selectedBathrooms !== "Any") {
      updatedParams["numBathrooms[gte]"] = selectedBathrooms;
    } else {
      delete updatedParams["numBathrooms[gte]"];
    }

    if (selectedBeds !== "Any") {
      updatedParams["numBeds[gte]"] = selectedBeds;
    } else {
      delete updatedParams["numBeds[gte]"];
    }

    updatedParams.minPrice = minPrice.toString(); //TODO как появятся названия на бэке - нужно будет их изменить
    updatedParams.maxPrice = maxPrice.toString();
    const searchParams = new URLSearchParams(
      updatedParams as Record<string, string>
    );
    navigate({
      pathname: "/search",
      search: `?${searchParams.toString()}`,
    });

    onClose();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchResults = useCallback(
    debounce(() => {
      fetchResults();
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedFetchResults.cancel();
    };
  }, [debouncedFetchResults]);

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          <img src={closeIcon} alt={t("filterModal.closeButton")} />
        </button>
        <div className={styles.modalContent}>
          <h2 className={styles.title}>{t("filterModal.title")}</h2>
          <div className={styles.filterSection}>
            <h3 className={styles.subtitle}>
              {" "}
              {t("filterModal.typeAllocationTitle")}
            </h3>
            <p className={styles.text}>
              {t("filterModal.typeAllocationDescription")}
            </p>
            <div className={styles.allocationButtons}>
              {["hotel", "apartment", "house"].map((type) => (
                <button
                  key={type}
                  className={
                    selectedType === type ? styles.activeButton : styles.button
                  }
                  onClick={() => handleTypeSelection(type)}
                >
                  {t(`filterModal.typeAllocation${type}`)}
                </button>
              ))}
            </div>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.filterSection}>
            <h3 className={styles.subtitle}>
              {t("filterModal.priceRangeTitle")}
            </h3>
            <div className={styles.priceSliderImage}>
              <PriceChart />
            </div>
            <Slider
              className={styles.priceSlider}
              thumbClassName={styles.sliderThumb}
              trackClassName={styles.sliderTrack}
              value={[minPrice, maxPrice]}
              min={0}
              max={35000}
              step={35}
              onChange={handleSliderChange}
            />
            <div className={styles.priceInputs}>
              {/* Input для минимальной цены */}
              <div className={styles.priceInput}>
                <label htmlFor="minPrice">
                  {t("filterModal.priceRangeMinLabel")}
                </label>
                <input
                  type="text"
                  id="minPrice"
                  value={formatInputValue(minPrice)}
                  onChange={handleMinPriceChange}
                  className={styles.input}
                />
              </div>

              {/* Input для максимальной цены */}
              <div className={styles.priceInput}>
                <label htmlFor="maxPrice">
                  {" "}
                  {t("filterModal.priceRangeMaxLabel")}
                </label>
                <input
                  type="text"
                  id="maxPrice"
                  value={formatInputValue(maxPrice, true)}
                  onChange={handleMaxPriceChange}
                  className={styles.input}
                />
              </div>
            </div>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.filterSection}>
            <h3 className={styles.subtitle}>{t("filterModal.roomsTitle")}</h3>
            <div className={styles.roomFilters}>
              {["bedrooms", "beds", "bathrooms"].map((type) => (
                <div key={type} className={styles.roomFiltersContent}>
                  <h4 className={styles.textType}>
                    {t(
                      `filterModal.rooms${
                        type.charAt(0).toUpperCase() + type.slice(1)
                      }`
                    )}
                  </h4>
                  <div className={styles.roomButtons}>
                    {["Any", "1", "2", "3", "4", "5+"].map((value) => (
                      <button
                        key={value}
                        className={`${styles.roomButton} ${
                          value === "Any"
                            ? styles.roomButtonAny
                            : value === "5+"
                            ? styles.roomButton5Plus
                            : ""
                        } ${
                          (type === "bedrooms" && selectedBedrooms === value) ||
                          (type === "beds" && selectedBeds === value) ||
                          (type === "bathrooms" && selectedBathrooms === value)
                            ? styles.activeRoomButton
                            : ""
                        }`}
                        onClick={() =>
                          handleRoomSelection(
                            type as "bedrooms" | "beds" | "bathrooms",
                            value
                          )
                        }
                      >
                        {value === "Any"
                          ? t("filterModal.roomsOptionAny")
                          : value}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.buttons}>
            <button className={styles.applyButton} onClick={handleApply}>
              {t("filterModal.applyButton", { count: totalOffers })}
            </button>
            <button className={styles.clearButton} onClick={handleClear}>
              {t("filterModal.clearButton")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
