import { FC, useState } from "react";
import Header from "../../components/header/header";
import FooterBottom from "../../components/footerBottom/footerBottom";
import UserDashboard from "../../components/userDashboard/userDashboard";

const ProfilePage: FC = () => {
    const userName = "Maria Ivanova";
    const userEmail = "lvanova@gmail.com"; // TODO надо будет заменить на информацию с бэкенда
  const [location, setLocation] = useState("Istanbul");
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [selectedDates, setSelectedDates] = useState<{
    checkIn: Date | undefined;
    checkOut: Date | undefined;
  }>({
    checkIn: undefined,
    checkOut: undefined,
  });
  const [guestCount, setGuestCount] = useState({
    adults: 1,
    children: 0,
    infants: 0,
    pets: 0,
  });
  return (
    <div>
      <Header
        location={location}
        onLocationChange={setLocation}
        availableDates={availableDates}
        onDateChange={(checkInDate, checkOutDate) => {
          setSelectedDates({
            checkIn: checkInDate,
            checkOut: checkOutDate,
          });
        }}
        checkIn={selectedDates.checkIn}
        checkOut={selectedDates.checkOut}
      />
      <UserDashboard username={userName} email={userEmail}/>
      <FooterBottom />
    </div>
  );
};
export default ProfilePage;
