import React from "react";
import { DayPicker, DateRange } from "react-day-picker";
import "react-day-picker/dist/style.css";
import styles from "./calendar.module.scss";
import { format } from "date-fns";
import { enUS, ru } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../context/notificationContext";

interface ICalendarProps {
  selectedDates: { checkIn: Date | undefined; checkOut: Date | undefined };
  availableDates: string[];
  onDateChange: (
    checkInDate: Date | undefined,
    checkOutDate: Date | undefined
  ) => void;
}

const Calendar: React.FC<ICalendarProps> = ({
  selectedDates,
  availableDates,
  onDateChange,
}) => {
  const { showNotification } = useNotification();
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language === "ru" ? ru : enUS;

  // Преобразуем доступные даты в Set для быстрой проверки
  const availableDatesSet = new Set(
    availableDates.map((date) => date.toString())
  );

  const handleDateChange = (range: DateRange | undefined) => {
    if (!range || !range.from || !range.to) {
      onDateChange(undefined, undefined);
      return;
    }

    // Проверяем, что все даты в диапазоне доступны
    const rangeIsValid = Array.from(
      {
        length:
          (range.to.getTime() - range.from.getTime()) / (1000 * 60 * 60 * 24) +
          1,
      },
      (_, i) => {
        //@ts-ignore
        const date = new Date(range.from.getTime() + i * 24 * 60 * 60 * 1000);
        return format(date, "yyyyMMdd"); // Преобразуем в формат YYYYMMDD
      }
    ).every((dateString) => availableDatesSet.has(dateString));

    if (rangeIsValid) {
      onDateChange(range.from, range.to);
    } else {
      showNotification(t("calendar.wrongPeriod"), "warning");
      onDateChange(undefined, undefined);
    }
  };

  const handleClearDates = () => {
    onDateChange(undefined, undefined);
  };

  return (
    <div className={styles["calendar-container"]}>
      <div className={styles["calendar-header"]}>
        <h3>{t("calendar.selectCheckIn")}</h3>
        <button className={styles["clear-dates"]} onClick={handleClearDates}>
          {t("calendar.clearDates")}
        </button>
      </div>
      <p>{t("calendar.addDatesForPricing")}</p>
      <div className={styles["calendar-inline"]}>
        {/* Первый календарь */}
        <DayPicker
          mode="range"
          selected={{ from: selectedDates.checkIn, to: selectedDates.checkOut }}
          onSelect={handleDateChange}
          numberOfMonths={1}
          disabled={[
            {
              before: new Date(),
            },
            (date) => {
              // Делаем недоступными все даты, которые не входят в доступные
              const formattedDate = format(date, "yyyyMMdd");
              return !availableDatesSet.has(formattedDate);
            },
          ]}
          locale={currentLocale}
          formatters={{
            formatCaption: (date) =>
              format(date, "LLLL yyyy", { locale: currentLocale }),
          }}
          styles={{
            day: { width: "62.35px", height: "48px", margin: "auto" },
            caption: {
              fontSize: "14px",
              fontWeight: "bold",
              textAlign: "center",
            },
            table: { maxWidth: "none" },
          }}
        />
        {/* Второй календарь */}
        <DayPicker
          mode="range"
          selected={{ from: selectedDates.checkIn, to: selectedDates.checkOut }}
          onSelect={handleDateChange}
          numberOfMonths={1}
          disabled={[
            {
              before: new Date(),
            },
            (date) => {
              // Делаем недоступными все даты, которые не входят в доступные
              const formattedDate = format(date, "yyyyMMdd");
              return !availableDatesSet.has(formattedDate);
            },
          ]}
          locale={currentLocale}
          formatters={{
            formatCaption: (date) =>
              format(date, "LLLL yyyy", { locale: currentLocale }),
          }}
          styles={{
            day: { width: "62.35px", height: "48px", margin: "auto" },
            caption: {
              fontSize: "14px",
              fontWeight: "bold",
              textAlign: "center",
            },
            table: { maxWidth: "none" },
          }}
        />
      </div>
    </div>
  );
};

export default Calendar;
