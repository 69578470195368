import React from "react";
import styles from "./userDashboard.module.scss";
import avatarIcon from "../../assets/images/avatar.png";

interface DashboardProps {
  username: string;
  email: string;
}

const UserDashboard: React.FC<DashboardProps> = ({ username, email }) => {
  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <div className={styles.profile}>
          <img className={styles.avatar} src={avatarIcon} alt="icon avatar"/>
          <div className={styles.userInfo}>
            <h2>{username}</h2>
            <div className={styles.emailAmdButton}>
            <p>{email}</p>
            <button className={styles.profileLink}>Go to profile</button>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.settingsGrid}>
        <div className={`${styles.settingCard} ${styles.largeCard}`}>
          <div className={styles.icon}>💼</div>
          <h3>Personal info</h3>
          <p>Provide personal details and how we can reach you</p>
        </div>
        <div className={`${styles.settingCard} ${styles.smallCard}`}>
          <div className={styles.icon}>💳</div>
          <h3>Payments & payouts</h3>
          <p>Review payments, payouts, coupons, and gift cards</p>
        </div>
        <div className={`${styles.settingCard} ${styles.smallCard}`}>
          <div className={styles.icon}>📑</div>
          <h3>Taxes</h3>
          <p>Manage taxpayer information and tax documents</p>
        </div>
        <div className={`${styles.settingCard} ${styles.smallCard}`}>
          <div className={styles.icon}>🔔</div>
          <h3>Notifications</h3>
          <p>Choose notification preferences and how you want to be contacted</p>
        </div>
        <div className={`${styles.settingCard} ${styles.smallCard}`}>
          <div className={styles.icon}>🔒</div>
          <h3>Privacy & sharing</h3>
          <p>Manage your personal data, connected services, and data sharing settings</p>
        </div>
        <div className={`${styles.settingCard} ${styles.largeCard}`}>
          <div className={styles.icon}>🔐</div>
          <h3>Login & security</h3>
          <p>Update your password and secure your account</p>
        </div>
        <div className={`${styles.settingCard} ${styles.smallCard}`}>
          <div className={styles.icon}>🌍</div>
          <h3>Global preferences</h3>
          <p>Set your default language, currency, and timezone</p>
        </div>
        <div className={`${styles.settingCard} ${styles.smallCard}`}>
          <div className={styles.icon}>🛫</div>
          <h3>Travel for work</h3>
          <p>Add a work email for business trip benefits</p>
        </div>
        <div className={`${styles.settingCard} ${styles.smallCard}`}>
          <div className={styles.icon}>🏡</div>
          <h3>Professional hosting tools</h3>
          <p>Get professional tools if you manage several properties on Airbnb</p>
        </div>
        <div className={`${styles.settingCard} ${styles.smallCard}`}>
          <div className={styles.icon}>🎁</div>
          <h3>Referral credit & coupon</h3>
          <p>You have $0 referral credits and coupon.</p>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
