import React from "react";
import styles from "./hostDetails.module.scss";
import verifyIcon from "../../assets/images/verify_icon.png";
import reviewsIcon from "../../assets/images/reviews_icon.png";
import warningIcon from "../../assets/images/warning_icon.png";
import { useTranslation } from "react-i18next";

interface HostDetailsProps {
  name: string;
  joinedDate: string;
  verified: boolean;
  reviewsCount: number;
  bio: string;
  languages: string[];
  responseRate: string;
  responseTime: string;
  avatarUrl: string;
}

const HostDetails: React.FC<HostDetailsProps> = ({
  name,
  joinedDate,
  verified,
  reviewsCount,
  bio,
  languages,
  responseRate,
  responseTime,
  avatarUrl,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles["host-details"]}>
      <div className={styles["host-main"]}>
        <img
          src={avatarUrl}
          alt={t("hostDetails.avatarAlt", { name })}
          className={styles["host-avatar"]}
        />
        <div className={styles["host-info"]}>
          <h3 className={styles["host-name"]}>
            {t("hostDetails.hostedBy", { name })}
          </h3>
          <p className={styles["host-joined"]}>
            {t("hostDetails.joinedIn", { date: joinedDate })}
          </p>
          <div className={styles["host-status"]}>
            {verified && (
              <div className={styles["verify-block"]}>
                <img
                  src={verifyIcon}
                  alt={t("hostDetails.verifyAlt")}
                  className={styles["verify-icon"]}
                />
                <span className={styles["host-verified"]}>
                  {t("hostDetails.identityVerified")}
                </span>
              </div>
            )}
            <div className={styles["reviews-block"]}>
              <img
                src={reviewsIcon}
                alt={t("hostDetails.reviewsAlt")}
                className={styles["reviews-icon"]}
              />
              <span className={styles["host-reviews"]}>
                {t("hostDetails.reviews", { count: reviewsCount })}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["host-details-bottom"]}>
        <div className={styles["contact-host"]}>
          <p className={styles["host-bio"]}>{bio}</p>
          <button className={styles["contact-host-button"]}>
            {t("hostDetails.contactHost")}
          </button>
        </div>
        <div className={styles["host-additional-block"]}>
          <div className={styles["host-additional"]}>
            <p className={styles["host-additional-element"]}>
              {t("hostDetails.languages")}: {languages.join(", ")}
            </p>
            <p className={styles["host-additional-element"]}>
              {t("hostDetails.responseRate")}: {responseRate}
            </p>
            <p className={styles["host-additional-element"]}>
              {t("hostDetails.responseTime")}: {responseTime}
            </p>
          </div>
          <div className={styles["host-warning"]}>
            <img
              src={warningIcon}
              alt={t("hostDetails.warningAlt")}
              className={styles["warning-icon"]}
            />
            <span className={styles["host-warning-text"]}>
              {t("hostDetails.warningMessage")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostDetails;
