import { FC, useEffect, useState } from "react";
import FooterBottom from "../../components/footerBottom/footerBottom";
import styles from "./mainPage.module.scss";
import Header from "../../components/header/header";
import { useTranslation } from "react-i18next";
import SearchBlock from "../../components/searchBlock/searchBlock";
import { Typewriter } from "react-simple-typewriter";
import { useNavigate } from "react-router-dom";
import { calculateNights, formatDate } from "../../utils/helpers";
import { fetchSearchSuggestions } from "../../api/api";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";

const MainPage: FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [location, setLocation] = useState("");
  const [lon, setLon] = useState<null | number>(null);
  const [lat, setLat] = useState<null | number>(null);
  const handleLocationChange = (value: string) => {
    setShouldFetchSuggestions(true);
    setLocation(value);
  };
  const [rooms, setRooms] = useState(1);
  const [beds, setBeds] = useState(1);
  const [radius, setRadius] = useState(5);
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [selectedDates, setSelectedDates] = useState<{
    checkIn: Date | undefined;
    checkOut: Date | undefined;
  }>({
    checkIn: undefined,
    checkOut: undefined,
  });
  const [guestCount, setGuestCount] = useState({
    adults: 1,
    children: 0,
    infants: 0,
    pets: 0,
  });

  const [isDisableSearchButton, setIsDisableSearchButton] = useState(true);
  // Состояние для автоподсказок
  const [locationSuggestions, setLocationSuggestions] = useState<
    ISearchSuggestionItem[]
  >([]);
  // Состояние для определения ввода лоакации - ручной или автоматический
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false);

  useEffect(() => {
    if(lat && lon) {
      setIsDisableSearchButton(false);
    }
  }, [lat, lon]);

  useEffect(() => {
    // Делаем запрос, только если длина более 2 символов
    if (shouldFetchSuggestions && location.length > 2) {
      const delayDebounceFn = setTimeout(() => {
        fetchSearchSuggestions(location, i18n.language)
          .then((data) => {
            // Здесь data — массив подсказок от бэкенда
            if (data["hydra:totalItems"] > 0) {
              const tempArray: ISearchSuggestionItem[] = [];
              data["hydra:member"].forEach((item) => {
                tempArray.push(item);
              });
              setLocationSuggestions(tempArray);
            }
          })
          .catch((error) => {
            console.error("Ошибка при получении подсказок:", error);
          });
      }, 500);

      // Очистим таймер при изменении location до истечения задержки,
      // чтобы не дергать сервер на каждый введенный символ
      return () => clearTimeout(delayDebounceFn);
    } else {
      // Если длина строки <= 2, сбросим подсказки
      setShouldFetchSuggestions(false);
      setLocationSuggestions([]);
    }
  }, [location, i18n.language]);

  const handleChangeGuests = (
    type: "adults" | "children" | "infants" | "pets",
    change: number
  ) => {
    setGuestCount((prevState) => {
      const newCount = prevState[type] + change;
      if (newCount >= 0) {
        return { ...prevState, [type]: newCount };
      }
      return prevState;
    });
  };
  // Анимационные строки
  const phrases = [t("mainPage.homeForWork"), t("mainPage.hofowo")];

  const handleSearch = () => {
   
    const queryParams: Record<string, string> = {
      "geoPoint[lat]": lat!.toString(),
      "geoPoint[lon]": lon!.toString(),
    };
    queryParams["geoPoint[distance]"] = radius.toString();

    if (selectedDates.checkIn) {
      queryParams["book[from]"] = formatDate(selectedDates.checkIn);
    }

    if (selectedDates.checkOut) {
      queryParams["book[to]"] = formatDate(selectedDates.checkOut);
    }

    if (selectedDates.checkIn && selectedDates.checkOut) {
      queryParams["book[nights]"] = calculateNights(
        selectedDates.checkIn,
        selectedDates.checkOut
      ).toString();
    }
    if (location) {
      queryParams["nameLocation"] = location;
    }

    if (guestCount.adults) {
      queryParams["adults"] = guestCount.adults.toString();
    }

    if (guestCount.children) {
      queryParams["children"] = guestCount.children.toString();
    }

    queryParams["numBeds[gte]"] = beds.toString();
    queryParams["numBedrooms[gte]"] = rooms.toString();

    // Формируем строку запроса
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    // Навигация на страницу поиска
    navigate(`/search?${queryString}`);
  };

  const handleChangeRooms = (change: number) => {
    setRooms((prevRooms) => {
      const newRooms = prevRooms + change;
      return newRooms >= 1 ? newRooms : 1;
    });
  };

  const handleChangeBeds = (change: number) => {
    setBeds((prevBeds) => {
      const newBeds = prevBeds + change;
      return newBeds >= 1 ? newBeds : 1;
    });
  };

  const handleRadiusChange = (newRadius: number) => {
    setRadius(newRadius);
  };

  const handleSelectSuggestion = (suggestion: ISearchSuggestionItem) => {
    setShouldFetchSuggestions(false);
    setLocation(suggestion.name);
    setLocationSuggestions([]);
    // TODO возможно логику извлечения радиусов придется поменять - зависит от бэка
    const numericPart = suggestion.radius.replace(/\D+/g, "");
    const numberRadius = Number(numericPart);
    if (numberRadius && numberRadius >= 5 && numberRadius <= 100) {
      setRadius(numberRadius);
    }
    setLat(suggestion.latLon.lat);
    setLon(suggestion.latLon.lon);
  };

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.mainContent}>
          <Header
            location={location}
            onLocationChange={handleLocationChange}
            availableDates={availableDates}
            onDateChange={(checkInDate, checkOutDate) => {
              setSelectedDates({
                checkIn: checkInDate,
                checkOut: checkOutDate,
              });
            }}
            checkIn={selectedDates.checkIn}
            checkOut={selectedDates.checkOut}
            isMainPage={true}
          />
          <h1 className={styles.title}>
            <Typewriter
              key={i18n.language}
              words={phrases}
              loop={1} // Анимация запускается один раз
              typeSpeed={100} // Скорость печати
              deleteSpeed={50} // Скорость удаления
              delaySpeed={1500} // Задержка перед переключением фразы
            />
          </h1>
          <div className={styles.searchContent}>
            <SearchBlock
              handleSearch={handleSearch}
              location={location}
              onLocationChange={handleLocationChange}
              onDateChange={(checkInDate, checkOutDate) => {
                setSelectedDates({
                  checkIn: checkInDate,
                  checkOut: checkOutDate,
                });
              }}
              checkIn={selectedDates.checkIn}
              checkOut={selectedDates.checkOut}
              availableDates={availableDates}
              allowAllDates={true}
              rooms={rooms}
              onChangeRooms={handleChangeRooms}
              beds={beds}
              onChangeBeds={handleChangeBeds}
              radius={radius}
              onChangeRadius={handleRadiusChange}
              guestCount={guestCount}
              onChangeGuests={handleChangeGuests}
              locationSuggestions={locationSuggestions}
              onSelectSuggestion={handleSelectSuggestion}
              isDisableSearchButton={isDisableSearchButton}
            />
            <p className={styles.travelText}>{t("mainPage.travelText")}</p>
          </div>
        </div>
        <FooterBottom isMainPage={true} />
      </div>
    </div>
  );
};

export default MainPage;
