import React, { useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

const NotFoundPage: React.FC = () => {
  const [location, setLocation] = useState("");

  const handleLocationChange = (value: string) => {
    setLocation(value);
  };

  const [selectedDates, setSelectedDates] = useState<{
    checkIn: Date | undefined;
    checkOut: Date | undefined;
  }>({
    checkIn: undefined,
    checkOut: undefined,
  });

  return (
    <div>
      <Header
        location={location}
        onLocationChange={handleLocationChange}
        availableDates={[]}
        onDateChange={(checkInDate, checkOutDate) => {
          setSelectedDates({
            checkIn: checkInDate,
            checkOut: checkOutDate,
          });
        }}
      />
      <div className="other-page-content">
        <h1>Not Found</h1>
      </div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
