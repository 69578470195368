import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DayPicker, DateRange } from "react-day-picker";
import LanguageSwitcher from "../languageSwitcher/languageSwitcher";
import styles from "./header.module.scss";
import leftHeader from "../../assets/images/icons-for-mainPage/background.png";
import locationMarker from "../../assets/images/location-marker.png";
import calendarIcon from "../../assets/images/calendar.png";
import usersIcon from "../../assets/images/users.png";
import adjustmentIcon from "../../assets/images/adjustments.png";
import searchIcon from "../../assets/images/search_icon.png";
import userAvatar from "../../assets/images/avatar.png";
import { format } from "date-fns";
import { enUS, ru } from "date-fns/locale";
import { useNotification } from "../../context/notificationContext";
import { useNavigate } from "react-router-dom";
import { calculateNights, formatDate } from "../../utils/helpers";
import FilterModal from "../filterModal/filterModal";
import guestLogoIcon from "../../assets/images/icons-for-mainPage/hofowo.png";
import dropDownMenuIcon from "../../assets/images/icons-for-mainPage/menu-alt-4.png";
import logoIconMobile from "../../assets/images/icons-for-mainPage/logoIconMobile.png";
import logoIcon from "../../assets/images/icons-for-mainPage/logo.png";
import LoginModal from "../loginPage/loginModal";
import { useAuth } from "../../context/authContext";

interface IHeaderProps {
  location: string | undefined;
  onLocationChange: (value: string) => void;
  onDateChange: (
    checkInDate: Date | undefined,
    checkOutDate: Date | undefined
  ) => void;
  availableDates: string[];
  checkIn?: Date | undefined;
  checkOut?: Date | undefined;
  adults?: number;
  children?: number;
  allowAllDates?: boolean;
  isMainPage?: boolean;
}

const Header: React.FC<IHeaderProps> = ({
  isMainPage = false,
  location = "",
  onLocationChange,
  onDateChange,
  availableDates,
  checkIn,
  checkOut,
  adults,
  children,
  allowAllDates = false,
}) => {
  const { isAuthenticated } = useAuth();
  const { t, i18n } = useTranslation();
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const handleOpen = () => setIsLoginOpen(true);
  const handleClose = () => setIsLoginOpen(false);
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const currentLocale = i18n.language === "ru" ? ru : enUS;
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);

  const handleFilterClick = () => {
    setFilterModalOpen(true);
  };

  const handleCloseModal = () => {
    setFilterModalOpen(false);
  };

  const handleSearch = () => {
    // TODO Захардкоженные параметры для запроса
    const lat = -16.476738;
    const lon = -151.919336;
    const distance = "50000km";

    const queryParams: Record<string, string> = {
      "geoPoint[lat]": lat.toString(),
      "geoPoint[lon]": lon.toString(),
      "geoPoint[distance]": distance,
    };

    if (checkIn) {
      queryParams["book[from]"] = formatDate(checkIn);
    }

    if (checkOut) {
      queryParams["book[to]"] = formatDate(checkOut);
    }

    if (checkIn && checkOut) {
      queryParams["book[nights]"] = calculateNights(
        checkIn,
        checkOut
      ).toString();
    }
    if (location) {
      queryParams["nameLocation"] = location;
    }

    if (adults) {
      queryParams["adults"] = adults.toString();
    }

    if (children) {
      queryParams["children"] = children.toString();
    }

    // Формируем строку запроса
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    // Навигация на страницу поиска
    navigate(`/search?${queryString}`);
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen((prev) => !prev);
  };

  // Преобразуем доступные даты в Set для быстрой проверки
  const availableDatesSet = new Set(
    availableDates.map((date) => date.toString())
  );

  const handleDateChange = (range: DateRange | undefined) => {
    if (!range || !range.from || !range.to) {
      onDateChange(undefined, undefined);
      return;
    }

    if (!allowAllDates) {
      // Проверяем, что все даты в диапазоне доступны
      const rangeIsValid = Array.from(
        {
          length:
            (range.to.getTime() - range.from.getTime()) /
              (1000 * 60 * 60 * 24) +
            1,
        },
        (_, i) => {
          //@ts-ignore
          const date = new Date(range.from.getTime() + i * 24 * 60 * 60 * 1000);
          return format(date, "yyyyMMdd"); // Преобразуем в формат YYYYMMDD
        }
      ).every((dateString) => availableDatesSet.has(dateString));

      if (!rangeIsValid) {
        showNotification(t("header.wrongPeriod"), "warning");
        onDateChange(undefined, undefined);
        return;
      }
    }
    onDateChange(range.from, range.to);
  };

  const handleResetDates = () => {
    onDateChange(undefined, undefined);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target as Node)
    ) {
      setIsDatePickerOpen(false);
    }
  };

  useEffect(() => {
    if (isDatePickerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDatePickerOpen]);

  return (
    <header className={styles.header}>
      <div className={styles["header-content"]}>
        {/* Левая часть */}
        {isAuthenticated ? (
          <div className={styles["header-left"]}>
            <div className={styles.navigation}>
              <div className={styles["location-tag"]}>
                <img
                  className={styles.leftHeaderIcon}
                  src={leftHeader}
                  alt="icon"
                />
                <img
                  src={logoIcon}
                  alt="logo"
                  className={styles.logo}
                  onClick={() => navigate("/")}
                />
                <div className={styles["location-place"]}>
                  <img
                    className={styles.locationMarker}
                    src={locationMarker}
                    alt="location marker"
                  />
                  <input
                    type="text"
                    value={location}
                    onChange={(e) => onLocationChange(e.target.value)}
                    className={styles["location-input"]}
                    placeholder={t("header.locationPlaceholder")}
                  />
                </div>
              </div>
              <div className={styles.separator}></div>
              <div className={styles["date-block"]} onClick={toggleDatePicker}>
                <img
                  src={calendarIcon}
                  alt="icon calendar"
                  className={styles["calendar-icon"]}
                />
                <span className={styles["calendar-text"]}>
                  {checkIn && checkOut
                    ? `${checkIn.toLocaleDateString()} - ${checkOut.toLocaleDateString()}`
                    : t("header.dates")}
                </span>
                {/* Date Picker */}
                {isDatePickerOpen && (
                  <div
                    className={styles["date-picker"]}
                    ref={datePickerRef}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <DayPicker
                      mode="range"
                      selected={{
                        from: checkIn,
                        to: checkOut,
                      }}
                      onSelect={handleDateChange}
                      disabled={[
                        {
                          before: new Date(),
                        },
                        (date) => {
                          if (allowAllDates) return false; // Если все даты разрешены, не блокируем
                          const formattedDate = format(date, "yyyyMMdd");
                          return !availableDatesSet.has(formattedDate);
                        },
                      ]}
                      locale={currentLocale}
                      formatters={{
                        formatCaption: (date) =>
                          format(date, "LLLL yyyy", { locale: currentLocale }),
                      }}
                      numberOfMonths={1}
                    />
                    <button
                      onClick={handleResetDates}
                      className={styles["reset-dates-button"]}
                    >
                      {t("header.Reset-Dates")}
                    </button>
                  </div>
                )}
              </div>
              <div className={styles.separator}></div>
              <div className={styles["guests-block"]}>
                <img
                  src={usersIcon}
                  alt="icon guests"
                  className={styles["guests-icon"]}
                />
                <span className={styles["guests-qantity"]}>
                  {(adults || 0) + (children || 0)}
                </span>
              </div>
              <div className={styles.separator}></div>
              <div className={styles["adjustment-block"]}>
                <img
                  src={adjustmentIcon}
                  alt="adjustments icon"
                  className={styles["adjustment-icon"]}
                  onClick={handleFilterClick}
                />
              </div>
              <img
                src={searchIcon}
                alt="search icon"
                onClick={handleSearch}
                className={styles["search-icon"]}
              />
            </div>
          </div>
        ) : (
          <picture onClick={() => navigate("/")} className={styles.picture}>
            <source srcSet={logoIconMobile} media="(max-width: 868px)" />
            <source srcSet={guestLogoIcon} media="(min-width: 869px)" />
            <img
              src={guestLogoIcon} // Fallback для браузеров без поддержки <picture>
              alt="logo"
              className={styles.logoGuest}
            />
          </picture>
        )}

        {/* Правая часть */}
        <div
          className={
            isAuthenticated
              ? styles["header-right"]
              : `${styles["header-right"]} ${styles["mr-40"]}`
          }
        >
          <LanguageSwitcher isMainPage={isMainPage} />
          {isAuthenticated ? (
            <div className={styles["user-avatar"]}>
              <img
                src={userAvatar}
                alt="User Avatar"
                className={styles["avatar-image"]}
              />
            </div>
          ) : (
            <button className={styles.buttonLogin} onClick={handleOpen}>
              {t("header.login")}
            </button>
          )}
        </div>
        <img
          className={styles.dropDownMenu}
          src={dropDownMenuIcon}
          alt={"icon menu"}
        />
      </div>
      <FilterModal isOpen={isFilterModalOpen} onClose={handleCloseModal} />
      {isLoginOpen && <LoginModal isOpen={isLoginOpen} onClose={handleClose} />}
    </header>
  );
};

export default Header;
