import React from "react";
import styles from "./cardList.module.scss";
import { Booking } from "../../types/booking";
import Card from "../card/card";

interface ICardListProps {
  bookings: Booking[];
}

const CardList: React.FC<ICardListProps> = ({ bookings }) => {
  return (
    <div className={styles.cardList}>
      {bookings.map((booking) => (
        <Card key={booking.id} booking={booking} />
      ))}
    </div>
  );
};

export default CardList;
