import React, { useState } from "react";
import styles from "./listingDescription.module.scss";
import { useTranslation } from "react-i18next";

interface Feature {
  id: string;
  icon: string;
  name: string;
  description: string;
}

interface ListingDescriptionProps {
  features: Feature[];
  translatedInfo: string;
  originalInfo: string;
}

const ListingDescription: React.FC<ListingDescriptionProps> = ({
  features,
  translatedInfo,
  originalInfo,
}) => {
  const { t } = useTranslation();
  const [showOriginal, setShowOriginal] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleOriginal = () => setShowOriginal((prev) => !prev);
  const toggleDescription = () => setShowFullDescription((prev) => !prev);

  return (
    <div className={styles["listing-description"]}>
      <div className={styles["listing-features"]}>
        {features.map((feature) => (
          <div key={feature.id} className={styles["feature-item"]}>
            <img
              src={feature.icon}
              alt={feature.name}
              className={styles["feature-icon"]}
            />
            <div className={styles["feature-text"]}>
              <h4 className={styles["feature-title"]}>{feature.name}</h4>
              <p className={styles["feature-description"]}>
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles["translation-info"]}>
        <p>
          {showOriginal ? originalInfo : translatedInfo}{" "}
          <button
            className={styles["translation-toggle"]}
            onClick={toggleOriginal}
          >
            {showOriginal
              ? t("listingDescription.showTranslation")
              : t("listingDescription.showOriginal")}
          </button>
        </p>
      </div>
      <div className={styles["description-text"]}>
        <p>
          {showFullDescription
            ? originalInfo
            : `${originalInfo.slice(0, 200)}...`}
        </p>
        <button
          className={styles["description-toggle"]}
          onClick={toggleDescription}
        >
          {showFullDescription
            ? t("listingDescription.showLess")
            : t("listingDescription.showMore")}
        </button>
      </div>
    </div>
  );
};

export default ListingDescription;
