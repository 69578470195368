import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CardList from "../../components/cardList/cardList";
import styles from "./searchResult.module.scss";
import { Booking } from "../../types/booking";
import { fetchSearchResults } from "../../api/api";
import Header from "../../components/header/header";
import Pagination from "../../components/pagination/pagination";
import { useTranslation } from "react-i18next";
import sortingIcon from "../../assets/images/icon-for-search/sort-descending.png";
import filterIcon from "../../assets/images/icon-for-search/adjustments.png";
import gridIcon from "../../assets/images/icon-for-search/view-grid.png";
import FooterBottom from "../../components/footerBottom/footerBottom";
import FilterModal from "../../components/filterModal/filterModal";
import { useQueryParams } from "../../hooks/useQueryParams";
import MapWithPrices from "../../components/mapWithPrices/mapWithPrices";
import gridAndMapIcon from "../../assets/images/icon-for-search/view-list-map.png";
import viewMapIcon from "../../assets/images/icon-for-search/view-map.png";

const SearchResult: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedDates, setSelectedDates] = useState<{
    checkIn: Date | undefined;
    checkOut: Date | undefined;
  }>({
    checkIn: undefined,
    checkOut: undefined,
  });
  const [location, setLocation] = useState("");
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [totalOffers, setTotalOffers] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30; // Данный параметр задан на бэке
  const locationUrl = useLocation();
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [viewMode, setViewMode] = useState<
    "listOnly" | "listAndMap" | "mapOnly"
  >("listOnly");

  const handleToggleView = () => {
    if (viewMode === "listOnly") {
      setViewMode("listAndMap");
    } else if (viewMode === "listAndMap") {
      setViewMode("mapOnly");
    } else {
      setViewMode("listOnly");
    }
  };

  const handleFilterClick = () => {
    setFilterModalOpen(true);
  };

  const handleCloseModal = () => {
    setFilterModalOpen(false);
  };

  const handleLocationChange = (value: string) => {
    setLocation(value);
  };

  const handlePageChange = (page: number) => {
    const updatedParams = { ...queryParams, page: page.toString() };
    const searchParams = new URLSearchParams(
      updatedParams as Record<string, string>
    );
    navigate(`?${searchParams.toString()}`);
    setCurrentPage(page);
  };

  const queryParams = useQueryParams();

  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true);
      setError(null);

      try {
        setCurrentPage(queryParams.page ? parseInt(queryParams.page, 10) : 1);

        if (queryParams["book[from]"]) {
          const checkInDate = new Date(
            `${queryParams["book[from]"].substring(0, 4)}-${queryParams[
              "book[from]"
            ].substring(4, 6)}-${queryParams["book[from]"].substring(6)}`
          );
          setSelectedDates((prev) => ({
            ...prev,
            checkIn: checkInDate,
          }));
        }

        if (queryParams["book[to]"]) {
          const checkOutDate = new Date(
            `${queryParams["book[to]"].substring(0, 4)}-${queryParams[
              "book[to]"
            ].substring(4, 6)}-${queryParams["book[to]"].substring(6)}`
          );
          setSelectedDates((prev) => ({
            ...prev,
            checkOut: checkOutDate,
          }));
        }

        const data = await fetchSearchResults({
          lat: queryParams["geoPoint[lat]"],
          lon: queryParams["geoPoint[lon]"],
          distance: queryParams["geoPoint[distance]"],
          page: queryParams.page,
          from: queryParams["book[from]"],
          nights: queryParams.nights,
          kind: queryParams.kind,
          numberBedrooms: queryParams["numBedrooms[gte]"],
          numberBathrooms: queryParams["numBathrooms[gte]"],
          numberBeds: queryParams["numBeds[gte]"],
        });
        setBookings(data["hydra:member"]);
        setTotalOffers(data["hydra:totalItems"]);
      } catch (err) {
        setError("Error fetching data from the server.");
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationUrl.search]);

  if (loading) {
    return <div className={styles.loading}>{t("searchResult.loading")}</div>;
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <div className={styles.searchResult}>
      <div className={styles.section}>
        <Header
          checkIn={selectedDates.checkIn}
          checkOut={selectedDates.checkOut}
          location={queryParams["nameLocation"]}
          onLocationChange={handleLocationChange}
          availableDates={[]}
          adults={queryParams.adults ? Number(queryParams.adults) : undefined}
          children={
            queryParams.children ? Number(queryParams.children) : undefined
          }
          allowAllDates={true}
          onDateChange={(checkInDate, checkOutDate) => {
            setSelectedDates({
              checkIn: checkInDate,
              checkOut: checkOutDate,
            });
          }}
        />
        <div className={styles.filters}>
          <p>
            {t("searchResult.moreThan")}{" "}
            <span className={styles.bold}>
              {t("searchResult.offers", { count: totalOffers })}
            </span>
          </p>
          <div className={styles.filterActions}>
            <button className={styles.filterButton}>
              <img
                src={sortingIcon}
                alt="sorting icon"
                className={styles.icon}
              />
              {t("searchResult.sorting")}
            </button>
            <button className={styles.filterButton} onClick={handleFilterClick}>
              <img src={filterIcon} alt="filter icon" className={styles.icon} />
              {t("searchResult.filter")}
            </button>
            <button className={styles.filterButton} onClick={handleToggleView}>
              <img
                src={
                  viewMode === "listOnly"
                    ? gridIcon
                    : viewMode === "listAndMap"
                    ? gridAndMapIcon
                    : viewMapIcon
                }
                alt="view icon"
                className={styles.icon}
              />
              {t("searchResult.views")}
            </button>
          </div>
        </div>
        {bookings.length > 0 ? (
          <section className={styles.content}>
            {viewMode === "listOnly" && <CardList bookings={bookings} />}
            {viewMode === "listAndMap" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <CardList bookings={bookings} />
                <MapWithPrices
                  bookings={bookings}
                  lat={Number(queryParams["geoPoint[lat]"] || 0)}
                  lon={Number(queryParams["geoPoint[lon]"] || 0)}
                  onClose={() => setViewMode("mapOnly")}
                  viewMode={viewMode}
                />
              </div>
            )}
            {viewMode === "mapOnly" && (
              <MapWithPrices
                bookings={bookings}
                lat={Number(queryParams["geoPoint[lat]"] || 0)}
                lon={Number(queryParams["geoPoint[lon]"] || 0)}
                onClose={() => setViewMode("listOnly")}
                viewMode={viewMode}
              />
            )}

            {viewMode !== "mapOnly" && (
              <Pagination
                currentPage={currentPage}
                totalItems={totalOffers}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
              />
            )}
          </section>
        ) : (
          <p className={styles.noResults}>{t("searchResult.noResults")}</p>
        )}
      </div>
      <FooterBottom />
      <FilterModal isOpen={isFilterModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default SearchResult;
