import L from "leaflet";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMap } from "react-leaflet";
import zoomInIcon from "../../assets/images/icon-for-search/plus.png";
import zoomOutIcon from "../../assets/images/icon-for-search/minus.png";
import locateIcon from "../../assets/images/icon-for-search/locate.png";
import { useNotification } from "../../context/notificationContext";

const CustomControl: FC = () => {
  const map = useMap();
  const { t } = useTranslation();
  const { showNotification } = useNotification();

  useEffect(() => {
    const controlContainer = L.Control.extend({
      onAdd: function () {
        const container = L.DomUtil.create("div", "custom-control-container");
        container.style.width = "40px";
        container.style.height = "120px";
        container.style.backgroundColor = "white";
        container.style.borderRadius = "10px";
        container.style.display = "flex";
        container.style.flexDirection = "column";
        container.style.justifyContent = "space-between";
        container.style.alignItems = "center";
        container.style.padding = "12px";
        container.style.boxShadow = "0px 22px 44px 0px #00000040";
        container.style.boxShadow = "0px 4px 4px 0px #00000040";
        container.style.position = "absolute";
        container.style.top = "19px";
        container.style.right = "24px";
        container.style.boxSizing = "border-box";

        // Кнопка увеличения масштаба
        const zoomInButton = L.DomUtil.create("button", "", container);
        zoomInButton.style.width = "24px";
        zoomInButton.style.height = "24px";
        zoomInButton.style.backgroundImage = `url(${zoomInIcon})`;
        zoomInButton.style.backgroundSize = "contain";
        zoomInButton.style.backgroundColor = "transparent";
        zoomInButton.style.border = "none";
        zoomInButton.style.cursor = "pointer";
        zoomInButton.title = t("customControl.zoomIn");
        L.DomEvent.on(zoomInButton, "click", () => {
          map.zoomIn();
        });

        // Кнопка уменьшения масштаба
        const zoomOutButton = L.DomUtil.create("button", "", container);
        zoomOutButton.style.width = "24px";
        zoomOutButton.style.height = "24px";
        zoomOutButton.style.backgroundImage = `url(${zoomOutIcon})`;
        zoomOutButton.style.backgroundSize = "contain";
        zoomOutButton.style.backgroundColor = "transparent";
        zoomOutButton.style.border = "none";
        zoomOutButton.style.cursor = "pointer";
        zoomOutButton.title = t("customControl.zoomOut");
        L.DomEvent.on(zoomOutButton, "click", () => {
          map.zoomOut();
        });

        // Кнопка определения текущего местоположения
        const locateButton = L.DomUtil.create("button", "", container);
        locateButton.style.width = "24px";
        locateButton.style.height = "24px";
        locateButton.style.backgroundImage = `url(${locateIcon})`;
        locateButton.style.backgroundSize = "contain";
        locateButton.style.backgroundColor = "transparent";
        locateButton.style.border = "none";
        locateButton.style.cursor = "pointer";
        locateButton.title = t("customControl.locate");
        L.DomEvent.on(locateButton, "click", () => {
          if (!navigator.geolocation) {
            showNotification(
              t("customControl.geolocationNotSupported"),
              "warning"
            );
            return;
          }

          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              map.setView([latitude, longitude], 13);
            },
            () => {
              showNotification(t("customControl.locationError"), "warning");
            }
          );
        });

        return container;
      },
    });

    const controlInstance = new controlContainer({ position: "topright" });
    map.addControl(controlInstance);

    return () => {
      map.removeControl(controlInstance);
    };
  }, [map, t]);

  return null;
};

export default CustomControl;
