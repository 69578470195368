import React from "react";
import styles from "./guestModal.module.scss";
import minusIcon from "../../assets/images/minusIcon.png";
import plusIcon from "../../assets/images/plusIcon.png";
import { useTranslation } from "react-i18next";

interface GuestModalProps {
  guestCount: {
    adults: number;
    children: number;
    infants: number;
    pets: number;
  };
  onChange: (
    type: "adults" | "children" | "infants" | "pets",
    change: number
  ) => void;
}

const GuestModal: React.FC<GuestModalProps> = ({ guestCount, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.modal}>
      <div className={styles.content}>
        {/* Adults Section */}
        <div className={styles.guestItem}>
          <div className={styles.texts}>
            <p className={styles.textOption}>{t("guestModal.adults.title")}</p>
            <p className={styles.text}>{t("guestModal.adults.subtitle")}</p>
          </div>
          <div className={styles.controls}>
            <button
              className={styles.button}
              onClick={() => onChange("adults", -1)}
              disabled={guestCount.adults <= 0}
            >
              <img src={minusIcon} alt="minus icon" />
            </button>
            <span>{guestCount.adults}</span>
            <button
              className={styles.button}
              onClick={() => onChange("adults", 1)}
            >
              <img src={plusIcon} alt="plus icon" />
            </button>
          </div>
        </div>

        {/* Children Section */}
        <div className={styles.guestItem}>
          <div className={styles.texts}>
            <p className={styles.textOption}>
              {t("guestModal.children.title")}
            </p>
            <p className={styles.text}>{t("guestModal.children.subtitle")}</p>
          </div>
          <div className={styles.controls}>
            <button
              className={styles.button}
              onClick={() => onChange("children", -1)}
              disabled={guestCount.children <= 0}
            >
              <img src={minusIcon} alt="minus icon" />
            </button>
            <span>{guestCount.children}</span>
            <button
              className={styles.button}
              onClick={() => onChange("children", 1)}
            >
              <img src={plusIcon} alt="plus icon" />
            </button>
          </div>
        </div>

        {/* Infants Section */}
        <div className={styles.guestItem}>
          <div className={styles.texts}>
            <p className={styles.textOption}>{t("guestModal.infants.title")}</p>
            <p className={styles.text}>{t("guestModal.infants.subtitle")}</p>
          </div>
          <div className={styles.controls}>
            <button
              className={styles.button}
              onClick={() => onChange("infants", -1)}
              disabled={guestCount.infants <= 0}
            >
              <img src={minusIcon} alt="minus icon" />
            </button>
            <span>{guestCount.infants}</span>
            <button
              className={styles.button}
              onClick={() => onChange("infants", 1)}
            >
              <img src={plusIcon} alt="plus icon" />
            </button>
          </div>
        </div>

        {/* Pets Section */}
        <div className={styles.guestItem}>
          <div className={styles.texts}>
            <p className={styles.textOption}>{t("guestModal.pets.title")}</p>
          </div>
          <div className={styles.controls}>
            <button
              className={styles.button}
              onClick={() => onChange("pets", -1)}
              disabled={guestCount.pets <= 0}
            >
              <img src={minusIcon} alt="minus icon" />
            </button>
            <span>{guestCount.pets}</span>
            <button
              className={styles.button}
              onClick={() => onChange("pets", 1)}
            >
              <img src={plusIcon} alt="plus icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestModal;
