import { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./footerBottom.module.scss";

interface IFooterBottom {
  isMainPage?: boolean;
}
const FooterBottom: FC<IFooterBottom> = ({ isMainPage = false }) => {
  const { t } = useTranslation();

  return (
    <div className={styles["footer-bottom"]}>
      <span className={isMainPage ? styles.white : ""}>
        2025 {t("footerBottom.companyName")}
      </span>
      <div className={styles["footer-links"]}>
        <a
          href="/help"
          className={`${styles.link} ${styles["footer-link-visible"]} ${
            isMainPage ? styles.white : ""
          }`}
        >
          {t("footerBottom.help")}
        </a>
        <a
          href="/sitemap"
          className={
            isMainPage ? `${styles.link} ${styles.white}` : styles.link
          }
        >
          {t("footerBottom.sitemap")}
        </a>
        <a
          href="/terms"
          className={`${styles.link} ${styles["footer-link-visible"]} ${
            isMainPage ? styles.white : ""
          }`}
        >
          {t("footerBottom.terms")}
        </a>
        <a
          href="/privacy"
          className={
            isMainPage ? `${styles.link} ${styles.white}` : styles.link
          }
        >
          {t("footerBottom.privacyPolicy")}
        </a>
      </div>
    </div>
  );
};
export default FooterBottom;
