import React from "react";
import styles from "./pagination.module.scss";
import chevronLeftIcon from "../../assets/images/icon-for-search/chevron-left.png";
import chevronRightIcon from "../../assets/images/icon-for-search/chevron-right.png";

interface PaginationProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) onPageChange(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) onPageChange(currentPage + 1);
  };

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className={styles.pagination}>
      <button onClick={handlePrevious} disabled={currentPage === 1}>
        <img src={chevronLeftIcon} alt="left arrow icon" />
      </button>
      {pages.map((page) => (
        <button
          key={page}
          className={currentPage === page ? styles.active : ""}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      ))}
      <button onClick={handleNext} disabled={currentPage === totalPages}>
        <img src={chevronRightIcon} alt="right arrow icon" />
      </button>
    </div>
  );
};

export default Pagination;
