import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { getAddressFromCoordinates } from "../../api/api";
import "leaflet/dist/leaflet.css";
import styles from "./map.module.scss";
import marketIcon from "../../assets/images/market_icon.png";
import { useTranslation } from "react-i18next";

interface MapProps {
    lat: number;
    lon: number;
}

const Map: React.FC<MapProps> = ({ lat, lon }) => {
  const { t, i18n } = useTranslation();
  const [address, setAddress] = useState<string | null>(null);

  useEffect(() => {
    // Получаем адрес при загрузке
    const fetchAddress = async () => {
      const fetchedAddress = await getAddressFromCoordinates(
        lat,
        lon,
        i18n.language
      );
      setAddress(fetchedAddress);
    };
    fetchAddress();
  }, [lat,lon, i18n.language]);

  const customIcon = L.icon({
    iconUrl: marketIcon,
    iconSize: [60, 88.85],
  });

  return (
    <div className={styles["map-container"]} id="location">
      <h3 className={styles["map-heading"]}>{t("map.whereYouWillBe")}</h3>
      <p className={styles["map-address"]}>
        {address || t("map.loadingAddress")}
      </p>
      <MapContainer
        center={[lat, lon]}
        zoom={13}
        scrollWheelZoom={false}
        className={styles["leaflet-map"]}
      >
        <TileLayer
          url={`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=${i18n.language}`}
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        <Marker
          position={[lat, lon]}
          icon={customIcon}
        >
          <Popup>{address || t("map.loadingAddress")}</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default Map;
