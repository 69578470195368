import React, { useEffect, useRef, useState } from "react";
import styles from "./searchBlock.module.scss";
import locationIcon from "../../assets/images/icons-for-mainPage/location-marker.png";
import calendarIcon from "../../assets/images/icons-for-mainPage/calendar.png";
import radiusIcon from "../../assets/images/icons-for-mainPage/radius.png";
import usersIcon from "../../assets/images/icons-for-mainPage/users.png";
import roomsIcon from "../../assets/images/icons-for-mainPage/ion_bed.png";
import bedsIcon from "../../assets/images/icons-for-mainPage/beds.png";
import { useTranslation } from "react-i18next";
import adjustmentIcon from "../../assets/images/icons-for-mainPage/adjustments.png";
import { format } from "date-fns";
import { enUS, ru } from "date-fns/locale";
import RoomModal from "../roomModal/roomModal";
import BedsModal from "../bedsModal/bedsModal";
import RadiusModal from "../radiusModal/radiusModal";
import GuestModal from "../guestModal/guestModal";
import { TwoCalendarsRangePicker } from "../twoCalendarsRangePicker/twoCalendarsRangePicker";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import SuggestionsModal from "../suggestionsModal/suggestionsModal";
import chevronDownIcon from "../../assets/images/icons-for-mainPage/chevron-down.png";

interface ISearchBlock {
  handleSearch: () => void;
  location: string | undefined;
  onLocationChange: (value: string) => void;
  onDateChange: (
    checkInDate: Date | undefined,
    checkOutDate: Date | undefined
  ) => void;
  availableDates: string[];
  checkIn?: Date | undefined;
  checkOut?: Date | undefined;
  allowAllDates: boolean;
  rooms: number;
  onChangeRooms: (change: number) => void;
  beds: number;
  onChangeBeds: (change: number) => void;
  radius: number;
  onChangeRadius: (change: number) => void;
  guestCount: {
    adults: number;
    children: number;
    infants: number;
    pets: number;
  };
  onChangeGuests: (
    type: "adults" | "children" | "infants" | "pets",
    change: number
  ) => void;
  locationSuggestions: ISearchSuggestionItem[];
  onSelectSuggestion: (suggestion: ISearchSuggestionItem) => void;
  isDisableSearchButton: boolean;
}

const SearchBlock: React.FC<ISearchBlock> = ({
  handleSearch,
  location,
  onLocationChange,
  onDateChange,
  availableDates,
  checkIn,
  checkOut,
  allowAllDates,
  rooms,
  onChangeRooms,
  beds,
  onChangeBeds,
  radius,
  onChangeRadius,
  guestCount,
  onChangeGuests,
  locationSuggestions,
  onSelectSuggestion,
  isDisableSearchButton,
}) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const currentLocale = i18n.language === "ru" ? ru : enUS;
  const suggestionsRef = useRef<HTMLDivElement | null>(null);
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const radiusRef = useRef<HTMLDivElement | null>(null);
  const guestsRef = useRef<HTMLDivElement | null>(null);
  const roomsRef = useRef<HTMLDivElement | null>(null);
  const bedsRef = useRef<HTMLDivElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<string | null>(null);

  const handleClickOutside = (event: PointerEvent) => {
    const target = event.target as Node;

    if (
      (isModalOpen === "suggestions" &&
        suggestionsRef.current &&
        !suggestionsRef.current.contains(target)) ||
      (isModalOpen === "calendar" &&
        calendarRef.current &&
        !calendarRef.current.contains(target)) ||
      (isModalOpen === "radius" &&
        radiusRef.current &&
        !radiusRef.current.contains(target)) ||
      (isModalOpen === "guests" &&
        guestsRef.current &&
        !guestsRef.current.contains(target)) ||
      (isModalOpen === "rooms" &&
        roomsRef.current &&
        !roomsRef.current.contains(target)) ||
      (isModalOpen === "beds" &&
        bedsRef.current &&
        !bedsRef.current.contains(target))
    ) {
      setIsModalOpen(null);
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("pointerdown", handleClickOutside);
    } else {
      document.removeEventListener("pointerdown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("pointerdown", handleClickOutside);
    };
  }, [isModalOpen]);

  // Преобразуем доступные даты в Set для быстрой проверки
  const availableDatesSet = new Set(
    availableDates.map((date) => date.toString())
  );

  const handleClick = () => {
    handleSearch();
  };

  const handleFocus = () => {
    setIsModalOpen("suggestions");
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // Если новый фокус находится внутри подсказок, не закрываем их
    if (
      suggestionsRef.current &&
      suggestionsRef.current.contains(event.relatedTarget as Node)
    ) {
      return;
    }
    setIsModalOpen(null);
  };

  const handleSuggestionPointerDown = (event: React.PointerEvent) => {
    // Предотвращаем потерю фокуса
    event.preventDefault();
  };

  return (
    <div className={styles.searchBlock}>
      <div className={styles.inputs}>
        <div className={styles.inputDestinationBlock}>
          <div className={styles.imageDestinationBlock}>
            <img
              src={locationIcon}
              alt="location icon"
              className={styles.inputDestinationImage}
            />
          </div>
          <input
            value={location}
            onChange={(e) => onLocationChange(e.target.value)}
            className={styles.inputDestination}
            placeholder={t("searchBlock.placeholder")}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {locationSuggestions.length > 0 && isModalOpen === "suggestions" && (
            <div
              ref={suggestionsRef}
              onPointerDown={handleSuggestionPointerDown}
            >
              <SuggestionsModal
                locationSuggestions={locationSuggestions}
                onSelectSuggestion={onSelectSuggestion}
              />
            </div>
          )}
        </div>
        <div className={styles.row}>
          <div
            className={styles.inputCalendarBlock}
            onClick={() => setIsModalOpen("calendar")}
          >
            <div className={styles.imageCalendarBlock}>
              <img
                src={calendarIcon}
                alt="calendar icon"
                className={styles.inputCalendarIcon}
              />
            </div>
            <div className={styles.textInCalendarBlock}>
              <div
                className={
                  checkIn && checkOut
                    ? styles.inputCalendar
                    : `${styles.inputCalendar} ${styles.halfOpacity}`
                }
              >
                {checkIn && checkOut
                  ? `${format(checkIn, "d MMM", {
                      locale: currentLocale,
                    })} - ${format(checkOut, "d MMM", {
                      locale: currentLocale,
                    })}`
                  : t("searchBlock.calendarCheckIn")}
              </div>
              {checkIn && checkOut ? (
                ""
              ) : (
                <div className={styles.separator}>|</div>
              )}
              <div
                className={
                  checkIn && checkOut
                    ? styles.inputCalendar
                    : `${styles.inputCalendar} ${styles.halfOpacity}`
                }
              >
                {checkIn && checkOut ? "" : t("searchBlock.calendarCheckOut")}
              </div>
            </div>
            {/* Date Picker */}
            {isModalOpen === "calendar" && (
              <div
                className={styles["date-picker"]}
                ref={calendarRef}
                onClick={(e) => e.stopPropagation()}
              >
                <TwoCalendarsRangePicker
                  checkIn={checkIn}
                  checkOut={checkOut}
                  onDateChange={onDateChange}
                  currentLocale={currentLocale}
                  allowAllDates={allowAllDates}
                  availableDatesSet={availableDatesSet}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.mobileRow}>
        <div
          className={styles.optionMobileRadius}
          onClick={() => setIsModalOpen("radius")}
        >
          <div className={styles.textsInButton}>
            <p className={styles.smallTextInMobileButton}>
              {t("searchBlock.radius")}
            </p>
            <p className={styles.textInMobileButton}>
              {radius}
              {t("searchBlock.radiusResult")}
            </p>
          </div>
          <img
            src={chevronDownIcon}
            alt="chevron down icon"
            className={styles.chevronIcon}
          />
          {isModalOpen === "radius" && (
            <div ref={radiusRef}>
              <RadiusModal radius={radius} onChange={onChangeRadius} />
            </div>
          )}
        </div>
        <div
          className={styles.optionMobileGuest}
          onClick={() => setIsModalOpen("guests")}
        >
          <div className={styles.textsInButton}>
            <p className={styles.smallTextInMobileButton}>
              {t("searchBlock.guest")}
            </p>
            <p className={styles.textInMobileButton}>
              {guestCount.adults + guestCount.children}
            </p>
          </div>
          <img
            src={chevronDownIcon}
            alt="chevron down icon"
            className={styles.chevronIcon}
          />
          {isModalOpen === "guests" && (
            <div ref={guestsRef}>
              <GuestModal guestCount={guestCount} onChange={onChangeGuests} />
            </div>
          )}
        </div>
        <button className={styles.adjustmentButton}>
          <img
            src={adjustmentIcon}
            alt="adjustment icon"
            className={styles.adjustmentIcon}
          />
        </button>
      </div>
      <div className={styles.options}>
        <div
          className={`${styles.option} ${styles.flex1}`}
          onClick={() => setIsModalOpen("radius")}
        >
          <img
            src={radiusIcon}
            alt="radius icon"
            className={styles.radiusIcon}
          />
          <p className={styles.optionText}>{t("searchBlock.radius")}</p>
          <p className={styles.optionResult}>
            {radius}
            {t("searchBlock.radiusResult")}
          </p>
          {isModalOpen === "radius" && (
            <div ref={radiusRef}>
              <RadiusModal radius={radius} onChange={onChangeRadius} />
            </div>
          )}
        </div>
        <div className={styles.option} onClick={() => setIsModalOpen("guests")}>
          <img src={usersIcon} alt="users icon" className={styles.usersIcon} />
          <p className={styles.optionText}>{t("searchBlock.guest")}</p>
          <p className={styles.optionResult}>
            {guestCount.adults + guestCount.children}
          </p>
          {isModalOpen === "guests" && (
            <div ref={guestsRef}>
              <GuestModal guestCount={guestCount} onChange={onChangeGuests} />
            </div>
          )}
        </div>
        <div className={styles.option} onClick={() => setIsModalOpen("rooms")}>
          <img src={roomsIcon} alt="rooms icon" className={styles.radiusIcon} />
          <p className={styles.optionText}>{t("searchBlock.rooms")}</p>
          <p className={styles.optionResult}>{rooms}</p>
          {isModalOpen === "rooms" && (
            <div ref={roomsRef}>
              <RoomModal rooms={rooms} onChange={onChangeRooms} />
            </div>
          )}
        </div>
        <div className={styles.option} onClick={() => setIsModalOpen("beds")}>
          <img src={bedsIcon} alt="beds icon" className={styles.radiusIcon} />
          <p className={styles.optionText}>{t("searchBlock.beds")}</p>
          <p className={styles.optionResult}>{beds}</p>
          {isModalOpen === "beds" && (
            <div ref={bedsRef}>
              <BedsModal beds={beds} onChange={onChangeBeds} />
            </div>
          )}
        </div>
        <button
          className={styles.findButton}
          onClick={handleClick}
          disabled={isDisableSearchButton}
        >
          {t("searchBlock.findButton")}
        </button>
      </div>
    </div>
  );
};

export default SearchBlock;
