import { FC } from "react";
import styles from "./roomGallery.module.scss";
import { Photo } from "../../types/photo";
import { useTranslation } from "react-i18next";

interface RoomGalleryProps {
  photos: Photo[];
}

const RoomGallery: FC<RoomGalleryProps> = ({ photos }) => {
  const { t } = useTranslation();
  const filteredPhotos = photos.filter(
    (photo) => photo.semanticId === "bathroom"
  );
  const isSlider = filteredPhotos.length > 2;

  return (
    <div className={styles["room-gallery-wrapper"]}>
      <h2 className={styles["room-gallery-heading"]}>
        {t("roomGallery.whereYouSleep")}
      </h2>

      {/* Галерея */}
      <div
        className={`${styles["room-gallery"]} ${
          isSlider ? styles["room-gallery-slider"] : ""
        }`}
      >
        {filteredPhotos.map((photo, index) => (
          <div key={index} className={styles["room-gallery-item"]}>
            <img
              src={photo.storageUrl}
              alt={photo.description}
              className={styles["room-gallery-image"]}
            />
            <div className={styles["room-gallery-info"]}>
              <h3 className={styles["room-gallery-title"]}>
                {photo.semanticId}
              </h3>
              <p className={styles["room-gallery-subtitle"]}>
                {photo.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoomGallery;
